.infoCard {
  padding: 15px;
  border-radius: 10px;
  background-color: var(--grey-10);
  color: var(--grey-80);

  &.flex {
    padding: 0 !important;

    display: flex;
    align-items: center;

    & > div {
      padding: 15px;
    }

    & > div:last-child {
      flex: 1;
      border-top: 1px solid var(--grey-border);
      border-right: 1px solid var(--grey-border);
      border-bottom: 1px solid var(--grey-border);
      border-radius: 0 10px 10px 0;
    }

    & .icon {
      display: flex;
      border-right: 1px solid var(--grey-border);
      align-self: stretch;
      align-items: center;

      &.error {
        background-color: var(--prim-100);
        border-radius: 10px 0 0px 10px;
      }

      &.white {
        & svg path {
          fill: white;
        }
      }
    }

    & .title {
      margin-bottom: 0px !important;
    }
  }

  &.white {
    background-color: white;
  }

  & .title {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
}
