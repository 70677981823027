.switchContainer {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & > .onOffLabel {
    position: absolute;
    top: 6px;
    font-size: 8px;
    font-weight: 600;
    color: #fff;
    vertical-align: middle;
  }

  & > .onOverlay {
    display: none;
    left: 5px;

    &.onOverlayActive {
      display: block;
    }
  }

  & > .offOverlay {
    display: none;
    right: 5px;

    &.offOverlayActive {
      display: block;
    }
  }

  & > .switch {
    width: 42px;
    height: 100%;

    & > span {
      &[data-state='checked'] {
        transform: translateX(22px);
      }

      &[data-state='unchecked'] {
        transform: translateX(0px);
      }
    }
  }
}
