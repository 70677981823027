$theme-color: var(--prim-100);

.list-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.list-view-action,
.list-view-action * {
  color: var(--bg-light);
}

.list-view-table-sorted-by {
  color: var(--prim-100) !important;

  svg {
    stroke: var(--prim-100) !important;
  }
}

.list-view-no-data {
  padding-top: 30px;
  padding-bottom: 30px;
  align-self: center;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;

  .list-view-action {
    height: 40px;
    padding: 10px 20px;
  }
}

.search-wrap {
  position: relative;
  display: flex;
  height: 40px;

  .search-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%);
  }

  .list-view-search {
    background-color: var(--bg-light) !important;
    height: 40px;
    width: 200px;
    padding-left: 40px;
    font-family: var(--font);
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out;
    &:hover {
      background-color: var(--bg-dark) !important;
    }

    &:focus {
      background-color: white !important;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      border-color: #86b7fe;
    }
  }
}

.list-view-modal {
  height: 450px;
  overflow-y: auto;
  border-bottom: 1px solid lightgrey;

  th {
    height: 32px;
  }
}

.list-view-fullPage {
  margin-top: 30px;
}

.list-view-fullPage,
.list-view-fullPageNoMargin {
  th {
    height: 50px;
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }

  .list-view-table {
    border-radius: 5px 5px 0 0;
  }

  td {
    height: 50px;
  }
}

.list-view-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 2px 0 var(--grey-30);

  .list-view-table {
    width: 100%;
    min-width: 700px;
    border-collapse: collapse;
    table-layout: fixed;

    * {
      font-weight: 300;
      font-family: var(--font);
      text-align: left;
    }

    td:not(.icons) {
      padding: 0 10px;
      min-width: 100px;
      word-wrap: break-word;
    }

    svg {
      &.flip {
        transform: scaleY(-1);
      }
    }

    tbody {
      tr {
        height: 40px;
        background-color: var(--bg-light);
        border-width: 0 1px 1px 1px;
        border-color: lightgrey;
        border-style: solid;
        position: relative;

        &:hover {
          .tr-icon {
            visibility: visible !important;
          }
        }

        &.selectable {
          cursor: pointer;

          &:hover {
            background-color: var(--bg-dark);
          }
        }

        .tr-icon-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          border-radius: 8px;
          margin: 0;
          margin-left: auto;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          aspect-ratio: 1;

          &:hover {
            background-color: var(--grey-30);
          }
        }
        .tr-icon-container-container {
          height: 100%;
          display: flex;
          align-items: center;

          .tr-icon-container {
            padding: 2px;

            &:hover {
              svg {
                stroke: var(--prim-100);
                color: var(--prim-100);
              }

              .tr-icon-fill {
                fill: var(--prim-100);
              }
            }

            .tr-icon {
              visibility: hidden;
              zoom: 1.25;
            }

            .tr-icon-fill {
              fill: var(--body-color);
            }
          }
        }
      }

      .select-cell {
        width: 25px;
        padding: 0 1px 0 5px;
      }
      .select-cell + td.data-cell {
        padding-left: 5px;
      }
      .dark-row {
        background-color: #f6f7fa;
      }
    }

    thead {
      .icon-col {
        width: 75px;
        padding: 0;
      }

      tr {
        background-color: rgb(32, 35, 47);
        z-index: 2;
        position: sticky;
        top: 0;
      }

      th {
        padding: 0 10px;
        word-break: break-word;
        color: white;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        &:hover {
          cursor: pointer;
        }

        svg {
          stroke: white;
          margin-left: 6px;
          margin-bottom: 2px;
        }
      }

      .select-cell {
        width: 25px;
        padding: 0;
      }
      .select-cell + th {
        padding-left: 5px;
      }
    }
  }
}
