.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--grey-90);

  .oopsText {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 600;
  }

  .somethingText {
    font-size: 22px;
    font-weight: 300;
    margin-top: 50px;
  }

  .tryAgainButton {
    margin-top: 30px;
    width: 158px;
    height: 48px;
    background-color: var(--prim-10);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;

    .tryAgainText {
      color: var(--prim-100);
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .reportIssue {
    margin-top: 20px;
    cursor: pointer;
    color: var(--grey-70);
    font-weight: 500;
    font-size: 14px;

    svg {
      stroke: var(--grey-70);
    }
  }
}
