.ruleBranchContainer {
  display: flex;

  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 14px;

  &.emptyBranch {
    align-items: center;
  }

  .ruleBranchContent {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .ruleBranchActions {
    & svg {
      path {
        fill: var(--grey-60);
      }

      &:hover {
        cursor: pointer;

        path {
          fill: var(--grey-80);
        }
      }
    }
  }
}
