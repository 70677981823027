.innerInput {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 138px;
  height: 90px;
  border-radius: 5px;
  overflow: hidden;
  outline: 2px solid var(--grey-border);
  background-color: #eff3f8;
  z-index: 1;
  justify-items: center;

  path {
    transition: fill 0.2s;

    &:hover {
      fill: #d6dadf;
    }
  }

  path[name='paddingTop'] {
    cursor: n-resize;
  }
  path[name='paddingBottom'] {
    cursor: s-resize;
  }
  path[name='paddingLeft'] {
    cursor: w-resize;
  }
  path[name='paddingRight'] {
    cursor: e-resize;
  }
  & [contenteditable]:focus {
    outline: none;
  }

  .centerpiece {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    background: white;
    border: 1px solid var(--grey-border);
    z-index: 1;
    width: 100%;
  }
}

.inputContainer {
  place-content: center;
  display: grid;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.outerInput {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-rows: 1fr min-content 1fr;
  width: 230px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  outline: 2px solid var(--grey-border);
  background-color: white;
  justify-items: center;

  path {
    transition: fill 0.2s;

    &:hover {
      fill: #d6dadf;
    }
  }

  path[name='marginTop'] {
    cursor: n-resize;
  }
  path[name='marginBottom'] {
    cursor: s-resize;
  }
  path[name='marginLeft'] {
    cursor: w-resize;
  }
  path[name='marginRight'] {
    cursor: e-resize;
  }

  & [contenteditable]:focus {
    outline: none;
  }
  .innerInput {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}
