.disabled {
  cursor: not-allowed !important;
}

.radioButtonGroup {
  display: inline-flex;
  background: #eff3f8;
  border-radius: 5px;
  height: 32px;

  &.disabled {
    opacity: 0.6;
  }

  .label {
    display: flex !important;
    margin: 0 !important;
    padding: 2px;
    cursor: pointer;

    input {
      display: none;
    }

    .checked {
      background: white;
      box-shadow: 0 0 2px 2px #eaeaea;
    }

    .display {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      border-radius: 5px;
      transition: all 0.15s;
    }

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        .display:not(.checked) {
          background: #e3e6ed;
        }
      }
      .checked {
        background: white;
      }
    }
  }
}
