/* Reaching in and styleing the ACE code editor here */
.ace_gutter-cell.ace_error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAESSURBVHgBfVHLUcNADJXEJmM4cQNuTgdQAU5yydUd0EHogKEDt0AFvuaSYCrAHcS3cOQWT+K1Itlex/mMNbM7+ry3+yQhNLYOgvs7ojkjBhIGVRIxReboYbn8cjjUazOb+bjff4vrw3XLeDAYPy0WWUX4m07XPeCWtLX2hTaTyZsDo7UhMKctRHwLEDaR7xG9GxTdUqjrxnzICXG3i6u4LMMbY2JXR8RXFDl88rE0qiTIc8Aa/Nwt04XSsgQFg+fV/pkpIelqVhn6MhZFzMPhSU8y4pRk7j9HNfjZypCjJM115EaoC7sl+pXAhz5jzh5XqxGNkuRfZIw10QeuMNBs2pnuRBJz+c1NJlHJeVFE+rAmDqeQfvBBdTMJAAAAAElFTkSuQmCC);
}

.ace_gutter-layer {
  background-color: #f4f5f8 !important;
}

.ace_gutter-cell.ace_gutter-active-line {
  background-color: #e9eaee !important;
}

.ace_gutter-cell {
  font-size: 0.8em;
  padding-top: 3px;
  color: var(--grey-60);
}

div.ace_editor.ace_autocomplete {
  min-width: 30%;
}
