.switchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  font-weight: 500;
  gap: 7px;

  .disabled {
    color: var(--grey-60);
  }

  .enabled {
    color: var(--grey-80);
  }
}
