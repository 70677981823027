.documentSettingsPage {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--main-nav-height));
  background-color: white;
}

.flexLabel {
  display: flex;
}

.uploadButton {
  border: none;
  outline: none;
  padding: 8px 14px;
  font-size: 1rem;
  border-radius: 7px;
  transition: background-color ease-in-out 0.1s;
  min-width: 100px;
  background-color: var(--prim-100);
  color: white;

  &:hover {
    cursor: pointer;
    background-color: var(--button-primary-hover);
  }

  &:disabled {
    background-color: var(--prim-80);

    &:hover {
      cursor: not-allowed;
    }
  }
}
