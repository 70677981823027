.interactive {
  cursor: pointer !important;
  transition: all 250ms;

  &:hover {
    background: var(--grey-40) !important;
    color: black !important;

    .interactive {
      background: var(--grey-40) !important;
      color: black !important;
    }
  }
}

.numberInput {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  padding: 4px 4px 4px 8px;
  border: 1px solid var(--grey-40);
  border-radius: 0.25rem;
  background: white;
  transition: all 0.15s ease-in-out;

  &:has(> input:focus) {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    border-color: #86b7fe;
  }

  &.errored {
    border-color: var(--prim-100);
  }

  input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
  }

  .unit {
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    color: var(--grey-50);
    border-radius: 0.25rem;
  }

  .inputSvg {
    margin-right: 7px;
    stroke: currentColor;
    height: 16px;
    width: 16px;
  }

  .verticalFill {
    transform: rotate(90deg);
  }

  .noNumberUnit {
    padding: 0;
    color: currentColor;
  }
}

.noNumberInput {
  justify-content: center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid var(--grey-40);
  border-radius: 0.25rem;
  z-index: 10;
  width: 100%;
  padding: 4px;
}

.dropdownItem {
  padding: 4px;
  transition: all 250ms;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background: var(--grey-40);
  }
}
