.analyticsPage {
  padding: 0 35px;

  .headerContainer {
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .headerLabel {
      font-size: 2rem;
      font-weight: 600;
      font-family: var(--font);
    }

    .intervalToggle {
      width: 200px;
    }
  }

  .bodyContainer {
    background-color: white;
    border-radius: 8px;
    margin-top: 35px;
    padding: 40px;
    border: 1px solid var(--grey-30);

    .cardContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;

      .card {
        width: 24%;
        height: 150px;
        border: 1px solid var(--grey-30);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .cardIcon {
          border-radius: 50%;
          padding: 7px;
          margin-bottom: 5px;
        }

        .cardLabel {
          color: var(--grey-70);
        }
      }
    }

    .chartContainer {
      width: 100%;
      margin-bottom: 40px;

      .chartHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .chartLabel {
          margin-bottom: 5px;
          margin-right: 10px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .chart {
        width: 100%;
        height: 600px;
      }

      table {
        border: 1px solid;

        td,
        th {
          border: 1px solid;
          padding: 5px;
        }
      }
    }

    .cardStat {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .cardStatNumber {
        font-size: 28px;
        font-weight: 600;
      }

      .cardTrend {
        margin-left: 5px;
        margin-bottom: 9px;
        width: 40px;
        height: 18px;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    }
  }
}
