.ruleActionSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  background-color: var(--grey-80);
  color: #fff;
  padding: 4px 8px;
  border-radius: 6px;

  &:not(.readOnly) {
    padding-right: 0;

    svg {
      fill: #fff !important;
      stroke: #fff !important;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
