.blankStateContainer {
  z-index: 1;
  position: absolute;
  top: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // width / 2 - side padding
  left: calc(50% - 95px);
  width: 250px;

  svg {
    cursor: pointer;
  }

  .blankStateHeader {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
  }

  .blankStateBody {
    text-align: center;
  }

  .blankStateButton {
    height: 40px;
    width: 124px;
    padding: 0;
    margin: 20px 0 0 0;
  }
}

.formOnOff {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  gap: 8px;
}
