.ruleContainer {
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-direction: column;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    gap: 15px;
    flex-wrap: wrap;

    .ruleNameLeft {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .backButton {
        & svg {
          margin-right: 0 !important;
        }
      }

      .ruleHeader {
        margin: 0 0 0 16px;
        font-size: 1.8rem;
        font-weight: 600;
        font-family: var(--font);
      }

      .input {
        margin-left: 4px;
        max-width: 220px;
      }

      .ruleEditIcon {
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .ruleActions {
      display: flex;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid var(--grey-30);
      padding: 5px;
      gap: 10px;

      & .ruleStatus {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 10px;

        span {
          font-weight: 600;
          color: var(--grey-90);
        }

        .ruleStatusSwitch {
          zoom: 1.1;
        }
      }

      .delete {
        padding: 12px 18px;
        margin: 0;
      }
    }
  }

  .ruleContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 17px;
  }
}

.ruleFieldPanel {
  font-size: 16px;
  margin-left: 0 !important;
  margin-right: 0 !important;

  &:not(.card) .ruleFieldPanelCol {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.card {
    background-color: #fff;
    border-radius: 14px;
    border: 1px solid var(--grey-30);
    padding: 15px 15px 9px 15px;
  }

  .ruleFieldPanelCol {
    padding-inline: 4px;
    margin-bottom: 16px !important;
  }
}

.ruleField {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  font-size: 16px;

  & textarea {
    resize: none;
    flex: 1;
    flex-basis: 0;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  .fieldLabel {
    color: var(--grey-90);
    font-family: var(--font);
    font-weight: 600;
    font-size: 0.875em;
    display: flex;

    .linkIcon {
      margin-left: 5px;
      margin-top: -2px;
      cursor: pointer;

      svg {
        fill: black;
      }

      &:hover {
        svg {
          fill: var(--prim-100);
        }
      }
    }
  }

  .error {
    color: var(--error);
    display: flex;
    gap: 6px;
    margin-top: 4px;
  }
}

.ruleTabs {
  margin-left: 0 !important;
  margin-top: 5px !important;
}

.ruleTab {
  height: 100%;
}

div[class='tab-content']:has(> .ruleTab) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

div[class='tab-content']:has(> .ruleTab.codeEditor) {
  margin: 0 !important;
}
