.jsonEditor {
  border: 1px solid #e9e9e9;
  border-radius: 4px;

  & div[class='margin'] {
    background-color: #f4f5f8 !important; // gutter background color

    & div[class='current-line'] {
      background-color: #e9eaee !important;
    }
  }

  & div[class='line-numbers active-line-number'] {
    color: var(--grey-60) !important;
  }

  & div[class='line-numbers'] {
    color: var(--grey-60) !important;
  }
}

.readOnly {
  & div[class='view-line'] {
    & span {
      color: var(--grey-70) !important;
    }
  }
}

.placeholder {
  font-family: 'Courier New', Courier, monospace;
  position: absolute;
  top: 0;
  left: 70px;
}
