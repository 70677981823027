.categoryContainer {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  background-color: white;
  padding: 15px 15px;
  border-radius: 4px;
  border: 1px solid var(--grey-30);

  .categoryTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .filterTag {
    color: var(--grey-80);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: max-content;
    margin-bottom: 2px;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid white;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &.selected {
      border-color: var(--prim-100);
      font-weight: 600;
      background-color: #fff5f5;
    }
  }
}
