.dragLayer {
  // Crucial for preventing any 'auto' pointer events inside a custom drag preview from blocking canDrop
  * {
    pointer-events: none !important;
  }

  position: fixed;
  pointer-events: none;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;

  .defaultPreview {
    background-color: white;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
  }
}

.dragPreviewWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
