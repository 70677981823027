.elementPanel {
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.elementPanelTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(244, 245, 248);
  padding: 16px;
  font-weight: 600;
}

.header {
  display: flex;
  padding: 1.5em 0.5em 1em 1em;
  align-items: flex-start;

  .panelViewToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    width: 35px;
    height: 35px;
    margin-top: 5px;
    margin-left: 0.4em;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: all 250ms;

    &:hover {
      background-color: var(--grey-20);
    }
  }
}

.listPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blockPanel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}
