.sidePanel {
  z-index: 2000;
  transition: width 0.15s ease-in-out;
  overflow: visible;

  .content {
    transition: transform 0.2s ease-in-out;
    transform: translateX(0);
    position: relative;
    height: calc(100vh - var(--main-nav-height));
  }

  .panelCollapse {
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 32px;
    box-shadow: -3px 2px 11px rgba(87, 92, 130, 0.12);
    cursor: pointer;
  }

  .leftCollapse {
    position: absolute;
    right: -16px;
    top: 40px;
    z-index: -1;

    .caretIcon {
      position: absolute;
      right: 2px;
      top: 8px;
      width: 16px;
      height: 16px;
      stroke: #414859;
      transform: rotate(270deg);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .rightCollapse {
    position: absolute;
    left: -16px;
    top: 40px;
    z-index: -1;

    .caretIcon {
      position: absolute;
      left: 2px;
      top: 8px;
      width: 16px;
      height: 16px;
      stroke: #414859;
      transform: rotate(90deg);

      &.open {
        transform: rotate(270deg);
      }
    }
  }

  &.left {
    width: var(--selection-panel-width);

    .content {
      background-color: white;
      width: var(--selection-panel-width);
      overflow: visible;
    }
  }

  &.right {
    width: var(--editor-sidebar-width);

    .content {
      background-color: white;
      width: var(--editor-sidebar-width);
      overflow: visible;
    }
  }

  &.slideLeft {
    width: 0;

    .content {
      transform: translateX(-100%);
    }
  }

  &.slideRight {
    width: 0;

    .content {
      transform: translateX(100%);
    }
  }

  .sidebarContent {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: white;
  }
}
