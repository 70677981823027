.stepSelector {
  width: 100%;
  height: 48px;
  border: 1px solid var(--grey-30);
  margin-bottom: 40px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 250ms;
  position: relative;

  &:hover {
    &:not(.selected) {
      background-color: var(--grey-10);
    }

    .overflowTrigger {
      visibility: visible;
    }
  }

  &.selected {
    border-color: var(--prim-100);
    box-shadow: 0 0 4px 1px var(--prim-20);
    cursor: default;
  }

  .connectorBadge {
    position: absolute;
    top: -14px;
    right: -3px;
    padding: 0 6px;
    color: #fff;
    background-color: #000;
    border-radius: 9px;
    z-index: 2;
  }

  .input {
    margin-left: 4px;
  }

  .label {
    padding-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .overflowTrigger {
    visibility: hidden;
    cursor: pointer;
  }

  &.selected .plusButton {
    visibility: visible !important;
  }
  .plusButtonHolder {
    position: absolute;
    bottom: -21px;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    &.top {
      bottom: auto;
      top: -21px;

      .plusButton {
        top: auto !important;
        bottom: -8px;
        &.activeMenu,
        &.canDrop {
          bottom: -11.5px;
        }
      }
    }

    &.canDrop {
      background-color: var(--prim-10);
    }

    .plusButton {
      cursor: pointer;
      visibility: hidden;
      position: absolute;
      top: -8px;
      &:hover,
      &.activeMenu,
      &.canDrop {
        visibility: visible;
        stroke: var(--prim-100) !important;
        fill: var(--prim-100) !important;
      }
      &.activeMenu,
      &.canDrop {
        top: -11.5px;
      }
    }
  }
}
