.checkboxField {
  display: flex !important;
  align-items: center;
  gap: 10px;

  .fieldWrapper {
    display: flex;
  }

  .indicator {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    margin: auto 0;

    svg {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      polyline {
        stroke: #fff;
        fill: none;
        stroke-width: 1.8;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 100;
        stroke-dashoffset: 101;
        transition: all 300ms cubic-bezier(0.5, -0.5, 0.5, 1.5);
        vector-effect: non-scaling-stroke;
      }
    }
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-radius: 50%;
      background-color: var(--prim-100);
      transform: scale3d(0, 0, 1);
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  .display {
    display: flex;
    margin-left: 10px;
    font-size: 12px;
    font-family: var(--font);
    color: var(--grey-70);
    font-weight: 500;
  }

  input {
    display: none;
    &:checked + .indicator {
      svg polyline {
        stroke-dashoffset: 89.5;
      }
      &::after {
        z-index: 1;
        border-radius: 3px;
        transform: scale3d(1, 1, 1);
        transition: all 200ms cubic-bezier(0, 1, 0, 1);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    .display {
      opacity: 0.5;
    }

    .indicator {
      cursor: not-allowed;

      &::after {
        background-color: var(--grey-60);
      }
    }
  }
}
