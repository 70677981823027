.productSelector {
  width: 154px;
  max-width: 154px;
  margin-left: 0;
}

.productSelectorTable {
  margin-top: 24px;
}

.productEditContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.centered {
    justify-content: center;
  }
}

.input {
  width: 300px;
}

.error {
  color: var(--error);
  margin-top: 16px;
}
