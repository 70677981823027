.rollout-button--primary {
  background-color: var(--prim-100) !important;
  border-color: var(--prim-100) !important;
  font-weight: 600 !important;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.hide-save {
  .rollout-button--primary {
    visibility: hidden;
  }
}

.rollout-separator {
  width: 100%;
  border-top: 1px solid var(--grey-60);
  margin-bottom: 20px;
}

.rollout-automation-form__credentials,
.rollout-automation-form__trigger-trigger {
  width: 100%;
}

.rollout-automation-form__action-inputs-wrapper {
  dialog {
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    background-color: white;
  }
}

.rollout-credential-input__combobox-popover {
  z-index: 1000000000000 !important;
}

div[data-tippy-root] {
  z-index: 999999999 !important;
}

.switch-property-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .switch-property-container {
    margin-top: 20px;
    margin-bottom: 30px;

    .trigger-switch-input {
      width: 200px;
    }

    .rollout-automation-form__credentials {
      margin-bottom: 10px;
    }

    .switch-property-text {
      color: var(--grey-70);
    }
  }
}
