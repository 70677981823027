.navEdge {
  position: absolute;
  top: 0;
  width: calc(100% + 10px);
  height: 100%;
  pointer-events: none;

  .active {
    fill: #000;
    &:hover {
      fill: var(--prim-100) !important;
    }
  }

  .line {
    stroke: var(--grey-50);
    pointer-events: all;
    fill: var(--grey-50);

    &.active {
      stroke: #000;
      fill: #000;
    }

    .dropArea {
      rect {
        stroke: none;
        fill: none;
      }
      &.canDrop {
        rect {
          stroke: var(--prim-10) !important;
          fill: var(--prim-10) !important;
        }
      }
      &.activeMenu,
      &.canDrop {
        stroke: var(--prim-10) !important;
        fill: var(--prim-10) !important;
        .plusButton,
        g,
        line {
          stroke: var(--prim-100) !important;
          fill: var(--prim-100) !important;
        }
      }

      .plusButton {
        cursor: pointer;
        &:hover {
          stroke: var(--prim-100) !important;
          fill: var(--prim-100) !important;
        }
      }
    }
  }
}
