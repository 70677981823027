.flowContainer {
  display: grid;
  grid-template-columns: auto 500px;
  position: relative;
  height: calc(100vh - var(--main-nav-height));

  :global(.react-flow) {
    grid-column: 1;
    grid-row: 1;

    :global(.react-flow__pane) {
      cursor: default;
    }

    :global(.react-flow__minimap) {
      width: fit-content;
    }
  }

  .editStepsButton {
    position: absolute;
    left: 45px;
    top: 20px;
    grid-column: 1;
    grid-row: 1;
    width: 120px;
    height: 40px;
    background-color: var(--prim-10);
    color: var(--prim-100);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;

    svg {
      stroke: var(--prim-100);
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  .showConnections {
    position: absolute;
    bottom: 200px;
    left: 50px;
    grid-column: 1;
    grid-row: 1;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .flowMinimap {
    left: 30px !important;
    bottom: 30px !important;
  }

  .addStepButton {
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    z-index: 5;
    margin-top: 20px;

    &:hover {
      background-color: var(--button-primary-hover) !important;
    }
  }
}
