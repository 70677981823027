.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  transition: all 150ms;
  border-radius: 5px;
  width: 100%;
  height: 36px;
  position: relative;
  user-select: none;
  cursor: grab;

  &.separator {
    border-top: 1px solid var(--grey-30);
  }

  &.disabled {
    opacity: 0.5;
  }

  &.highlighted {
    background: var(--grey-20);
  }

  .icon {
    display: flex;
    min-width: 30px;
    width: 22px;
    height: 22px;
    overflow: hidden;
    margin-right: 10px;
  }

  .label {
    position: relative;
    font-size: 12px;
    font-family: var(--font);
    color: var(--grey-80);
    font-weight: 400;
    overflow-wrap: break-word;
    max-width: 100%;
    display: flex;
  }
}

.tooltipDiv {
  width: 100%;
}

.listContainer {
  margin-top: 5px;
  width: 100%;
}

.listContent {
  padding-left: 8px !important;
  padding-right: 0 !important;
  padding-bottom: 3px !important;
}

.listHeader {
  padding-right: 0 !important;
  padding-bottom: 5px !important;
}

.listTitle {
  font-size: 12px;
}

.noPadding {
  padding-left: 0 !important;
}

.reducedPadding {
  padding-left: 12px !important;
}
