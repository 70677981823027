.selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  /* Grayscale/30 */
  border: 1px solid #dbdfe8;
  box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
  border-radius: 5px;
}

.stepExpandIcon {
  transform: rotate(-90deg);
  fill: black;
}

.stepListContainer {
  height: 500px;
  overflow-y: scroll;
}

.selectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.selectButton {
  width: fit-content !important;
}

.selectedCount {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* Grayscale/70 */
  color: #6c7589;
}

.stepContainer {
  display: flex;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #dbdfe8;
  box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  margin-top: 35px;
}

.cancelButton {
  height: 50px;
  padding: 13px 15px 11px !important;
  margin-right: 8px;
  /* Grayscale/30 */
  border: 1px solid #dbdfe8 !important;
  box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
  border-radius: 6px !important;
}
