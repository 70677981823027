.templateContainer {
  height: 650px;
  background: white;
  overflow-y: auto;
  border-radius: 0 0 16px 16px;

  .templateTags {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .templateTag {
      padding: 5px 15px;
      margin-right: 5px;
      margin-bottom: 10px;
      border: 1px solid var(--grey-40);
      border-radius: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 300;

      &.selected {
        border-color: var(--prim-100);
        background-color: var(--prim-10);
        color: var(--prim-100);
      }
    }
  }

  .categorySection {
    border-bottom: 1px solid var(--grey-20);
    margin-bottom: 35px;

    .category {
      font-size: 16px;
      font-weight: 600;
      color: var(--grey-80);
      margin-bottom: 8px;
    }
  }

  .row {
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 2px;

    .card {
      height: 200px;
      display: flex;
      flex-direction: column;

      .imageWrapper {
        width: 100%;
        border-radius: 5px;
        background-color: rgba(244, 245, 248, 1);

        img {
          object-fit: none;
        }
      }

      img {
        width: 100%;
        height: 170px;
        cursor: pointer;
        border-radius: 5px;
        outline: 1px solid var(--grey-40);
        object-fit: cover;

        &:hover {
          outline: 2px solid var(--prim-100);
        }
      }

      .label {
        padding-top: 13px;
        font-weight: 200;
        font-size: 14px;
        color: var(--grey-80);
      }
    }
  }
}

.dialogHeader {
  height: 116px;
  border-radius: 16px 16px 0 0;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 22px;
  background: linear-gradient(
    90deg,
    rgba(255, 210, 229, 1) 0%,
    rgba(255, 248, 239, 1) 100%
  );

  h2 {
    margin: 0;
    font-size: calc(1.325rem + 0.9vw);
  }

  svg {
    position: absolute;
    right: 0;
  }
}
