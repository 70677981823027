.editableValue {
  display: flex;
  align-items: center;

  .valueDisplay {
    word-break: break-word;
  }

  .editInput {
    margin-left: -11px;
    width: min-content;
  }

  .toggle {
    margin-left: 10px;
    width: 21px;
    height: 21px;
    border-radius: 10px;
    background-color: var(--grey-20);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--grey-30);
    }

    svg {
      width: 20px;
      stroke: var(--grey-70);
    }
  }

  .editNew {
    color: var(--grey-60);
    cursor: pointer;
  }
}

.tabContainer {
  margin-top: 40px;
  background-color: white;
  border-radius: 8px;
  position: relative;

  .fieldTabs {
    width: 680px;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

    .fieldTab {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.overlayContainer {
  display: flex;
}
.deleteSubmission {
  width: max-content !important;
  height: min-content;
  align-self: center;

  &[disabled] {
    cursor: not-allowed;
    color: var(--grey-70) !important;
  }
}

.backContainer {
  display: flex;
  align-items: center;
  margin: 35px 0 20px;
}

.userDetailSettingsHeader {
  margin: 0 0 0 16px;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--font);
}

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.userDetailOverview {
  padding: 0 1rem 0 0 !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  height: 88px;

  .userDetailInfo {
    height: 100%;
    display: flex;
    flex-direction: row;

    .separator {
      height: 100%;
      width: 2px;
      background-color: var(--grey-10);
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.collaboratorEmails {
  display: flex;
  align-items: center;
}
.taskDetailsMore {
  width: 80px;
  color: var(--prim-100);
  cursor: pointer;
  margin-left: 5px;
}
.justifyCenter {
  justify-content: center;
}

.dateText {
  color: var(--grey-60);
  font-size: 12px;
  margin: 7px 0 0 6px;
}

.assetPreviewTooltip,
.assetPreviewTooltip > img {
  max-width: 256px;
  max-height: 256px;
}

.assetLink {
  display: block;
  max-width: max-content;
}

.assetText {
  color: var(--prim-100);
}

.envelopeTagContainer {
  display: flex;
  flex-wrap: wrap;

  .envelopeTag {
    margin-right: 5px;
    padding: 3px 6px;
    border-radius: 4px;
    background-color: var(--grey-70);
    color: white;
  }
}

.tagFilter.tagFilter {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  color: var(--prim-100) !important;

  &:hover:not(:disabled) {
    background-color: white !important;
  }
}
