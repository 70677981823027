.columnRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 15px;
  align-items: center;

  .columnLabel {
    width: 90px;
  }

  .columnDropdown {
    width: 60%;
    margin-right: 15px;
  }

  .trashIcon {
    cursor: pointer;
  }
}

.addColumn {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
