.share-modal {
  z-index: 1500;
  background-color: white;
  width: 230px;
  border-radius: 6px;
  margin-top: 3px;
  box-shadow: 0 3px 15px lightgrey;
  color: var(--grey-60);
  padding: 8px 0 10px 0;

  .share-header {
    padding: 10px 6px 3px 14px;
    width: 100%;
    border-top: 1px solid lightgrey;
    display: flex;
    align-items: center;

    &.status-header {
      justify-content: space-between;
    }
    .status {
      font-weight: 400;
      font-size: 10px;
      display: flex;
      align-items: center;
      gap: 3px;

      .clickable {
        cursor: pointer;
      }
      .dot {
        font-size: 18px;
      }
      .status-ok {
        color: var(--system-valid);
        svg {
          cursor: default;
          path {
            stroke: var(--system-valid);
          }
        }
      }
      .status-neutral {
        color: var(--grey-30);
      }
      .status-invalid {
        color: #e84343;
      }
    }
    svg {
      margin-left: 4px;
      padding-bottom: 1px;
      cursor: pointer;

      path {
        stroke: var(--grey-60);
      }

      &:hover {
        path {
          stroke: var(--prim-100);
        }
      }
    }
  }

  .share-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 7px 14px;

    &.first {
      padding-top: 5px;
    }

    .share-link-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .copy-link-container {
        width: 24px;
        height: 24px;
        background-color: var(--grey-30);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        svg {
          fill: var(--grey-90);
          width: 15px;
          height: 15px;
        }
      }
    }
    .status {
      margin-top: -4px;
      font-weight: 400;
      font-size: 10px;
      &.indent {
        margin-left: 28px;
      }
    }

    .share-link {
      padding-top: 4px;
      padding-bottom: 5px;
      cursor: pointer;
      color: var(--grey-90);
      display: flex;
      align-items: center;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;

      svg {
        margin-right: 10px;
        fill: var(--grey-90);
        width: 18px;
        height: 18px;
      }

      &.disabled {
        color: var(--grey-60);
        cursor: default;
        pointer-events: none;

        svg {
          fill: var(--grey-60);
        }
      }

      &:hover:not(.disabled) {
        color: var(--prim-100);
        text-decoration: underline;

        svg {
          fill: var(--prim-100);
        }
      }
    }
  }
}

.main-nav {
  position: sticky;
  display: flex;
  justify-content: space-between;
  background: var(--grey-90);
  top: 0;
  z-index: 20;
  height: var(--main-nav-height);
  overflow: hidden;

  .left-nav {
    display: flex;
    align-items: center;
    gap: 10px;

    .left-nav-header {
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 15px;
      overflow: hidden;
      cursor: default;
      padding: 16px;

      .back-container {
        padding: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        color: white;
        white-space: nowrap;
      }
    }

    a,
    .form-nav-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      height: 35px;
      border-radius: 6px;
      color: white;
      cursor: pointer;

      &.active {
        background-color: white;
        font-weight: 500;
        color: var(--prim-100);
      }

      &.disabled {
        cursor: default !important;
      }
    }
  }

  .right-sub-nav {
    display: flex;
    max-width: 478px;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 10px 10px 0;
  }
}

.page-nav {
  display: flex;
  position: relative;
  height: 100vh;
  // ArrowEdge horizontal position accounts for nav bar 70px width
  width: 180px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  background-color: rgb(32, 35, 47);
  z-index: 100;
  overflow: hidden;
  transition: all 0.3s;

  .page-nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;

    .logo {
      font-size: 25px;
      padding: 20px 0 20px 17px;
      margin-bottom: 10px;
      color: white;
      display: flex;

      svg {
        max-width: 40px;
        margin-right: 10px;
      }
    }

    .disabled-global-tab {
      cursor: default !important;
    }

    a:not(.logo),
    .disabled-global-tab,
    .logout {
      display: flex;
      font-size: 16px;
      color: #fff;
      margin: 2px 10px;
      padding: 15px;
      place-items: center;
      border: none;
      border-radius: 6px;
      position: relative;

      &.nav-background {
        background: url('../components/Icons/system/UpgradeBackground.png');
        background-size: cover;
        margin-bottom: 15px;
      }

      svg {
        stroke: white;
        margin-right: 15px;
      }

      &.active {
        background-color: white;
        color: var(--prim-100);

        svg {
          stroke: var(--prim-100);

          path {
            stroke: var(--prim-100);
          }
        }
      }

      &:not(.active):not(.disabled-global-tab):hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
