.color-picker-container {
  position: relative;
  box-shadow: 1px 0 0 0 var(--grey-30);

  .color-indicator {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 0.25rem 0 0 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    .open-indicator {
      display: flex;
      position: absolute;
      width: 12px;
      height: 12px;
      bottom: 3px;
      right: 3px;
    }
  }

  .color-picker {
    position: absolute;
    z-index: 3;

    .color-picker-close {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
