.workspacePage {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--main-nav-height));
  background-color: white;

  &.padded {
    padding-left: 20px;
  }

  table {
    border: 1px solid;
    width: 900px;

    td,
    th {
      border: 1px solid;
      padding: 10px;
      text-align: center;
    }
  }

  .deleteIcon {
    height: 20px;
    cursor: pointer;

    &:hover {
      stroke: var(--prim-100);
    }
  }

  .addUserInput {
    margin-top: 20px;
    width: 300px;
    gap: 20px;
    display: flex;
  }
}
