.fontPickerContainer {
  display: flex;

  .fontDropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fontCustomUploadIcon {
    width: 36px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .fontCustomUploadFileInput {
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    opacity: 0;
  }
}
