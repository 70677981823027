.searchWrap {
  display: flex;
  flex-direction: column;
  z-index: 1;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  box-sizing: border-box;

  &:global(.padded) {
    padding: 1.5em;
  }

  :global(.search-field-sources) {
    position: relative;
    flex: 1;
    border-radius: 0.4rem;
    overflow: hidden;

    input:global(.form-control) {
      padding: 10px;
      border-radius: 0.4rem;
      font-size: 1.1rem;
      &:focus {
        border-color: var(--prim-100);
      }
    }

    :global(.search-icon),
    :global(.clear-input) {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      place-content: center;
      place-items: center;
      transition: all 0.1s ease-in-out;
    }

    :global(.search-icon) {
      width: 3em;
      right: 0;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    :global(.clear-input) {
      width: 1em;
      right: 30px;
      font-weight: 100;
      font-size: 2.5em;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
    }

    :global(.form-control:focus) + :global(.clear-input) {
      visibility: visible;
      opacity: 1;
    }
  }

  :global(.info-message) {
    padding: 10px 10px 0 10px;
    font-size: 0.8em;
    letter-spacing: 1px;

    &:global(.notice) {
      color: #d26262;
    }
  }
}
