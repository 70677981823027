.assetActionPromptContainer {
  position: relative;
  height: 72px;
  border-top: 1px solid var(--grey-30);
  background: white;
  opacity: 1;
  overflow: hidden;
  transition: all 250ms;

  &.hidden {
    height: 0;
    transition: all 250ms;
    border-top: 0;
    opacity: 0;
  }

  .assetActionPrompt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms;
    z-index: 5;

    &.successMessage {
      transition: opacity 1s;
      z-index: 10;
      opacity: 1;

      &.hidden {
        transition: opacity 1s, z-index 0s 1s !important;
        z-index: -1;
        opacity: 0;
      }

      .assetActionButton {
        background-color: #f4f5f8;

        &:hover {
          background-color: #f4f5f8;
        }
      }

      .assetActionText {
        font-weight: normal;
      }

      .assetActionIcon * {
        fill: none !important;
        stroke: var(--prim-100) !important;
      }
    }

    &.hidden {
      z-index: -1;
    }

    .assetActionButton {
      height: 32px;
      width: 80%;
      font-weight: 700;
      background-color: var(--grey-30);
      color: var(--grey-80);
      border-radius: 4px;
      border: none;
      outline: none;
      transition: all 250ms;
      display: flex;
      align-items: center;
      justify-content: center;

      .assetActionText {
        margin-left: 6px;
      }

      &:hover {
        background-color: var(--grey-40);
      }
    }
  }
}

.assetActionIcon * {
  stroke: none;
  fill: var(--grey-80);
}

.createAssetModal {
  background: white;
  padding: 12px;
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 1fr min-content min-content;
  gap: 4px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

  .title {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    font-weight: 600;
  }

  .assetName {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .assetNameError {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    font-size: 10px;
  }

  .cancelButton {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
    color: var(--grey-60);
    cursor: pointer;
    font-weight: 600;
    border: none;
    outline: none;
  }

  .createButton {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
    color: var(--prim-100);
    cursor: pointer;
    font-weight: 600;
    border: none;
    outline: none;
  }
}
