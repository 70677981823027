// FormIntegrationsPage Begins
.searchAdjust {
  margin-top: 30px;
  margin-bottom: 2rem;

  input {
    width: 99% !important;
  }
}

.integrationsHeaderSubtitle {
  color: var(--grey-80);
  font-size: 14px;
}

.integrationsSubHeader {
  margin: 3rem 0 2rem 1rem;

  .integrationsSubHeaderLabel {
    font-size: 16px;
    line-height: 34px;
    font-weight: 700;
  }
}

.integrationsContainer {
  display: flex;
  height: calc(100% - var(--main-nav-height));
  padding: 0 0 0 0.25rem;

  .leftContainer {
    width: 270px;
    min-width: 270px;
    padding-left: 10px;
    padding-right: 5px;

    .headerTitle {
      margin-top: 32px;
    }
  }

  .integrationsScrollContainer {
    padding-left: 0.5rem;
    padding-right: 1rem;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}

.sidebar {
  padding-top: 1rem;
  height: 100%;
  margin-left: 0.75rem;
}

.sidebarOverflow {
  overflow-y: auto;
  height: 100%;
  padding-right: 0.5rem;
  scrollbar-gutter: stable;
}

.integrationCardsContainer {
  padding-right: 0.5rem;
}

// FormIntegrationsPage Ends

.pageHeadRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pageHeadTitle {
    margin-bottom: 25px;
  }
}
