.controlLabelBase {
  --control-blue: rgba(2, 123, 255, 1);
  --control-blue-dark: rgba(0, 90, 255, 1);

  background-color: var(--control-blue);
  color: white;

  &.noParents {
    background-color: var(--control-blue) !important;
  }

  &.noParents,
  .selected {
    background-color: transparent !important;
  }

  &:hover,
  .selected {
    background-color: var(--control-blue-dark);
  }

  &.unselected {
    background-color: transparent;
    color: var(--control-blue);
  }
}

.opacity {
  opacity: 0.5;
}

.controlLabelContainer {
  font-size: 11px;
  text-transform: uppercase;
  user-select: none;
  pointer-events: auto !important;
}

.parentLabelContainer {
  cursor: pointer;
  z-index: 1001;
  white-space: nowrap;
  margin-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  align-items: center;
}

.parentLabel {
  white-space: nowrap;
  font-size: 11px;
  position: relative;
  top: 1px;
}

.controlLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(2, 123, 255, 1);

  &.unselected {
    background-color: transparent;
  }
}

.focusedLabelContainer {
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  display: flex;
  height: 100%;
  padding-left: 4px;
}

.focusedLabel {
  white-space: nowrap;
  font-size: 11px;
  position: relative;
  top: 1px;
}

.sizeContainer {
  background-color: #003c7d;
  border-radius: 3px;
  padding: 0 2px;
  margin: 1px 3px 0 3px;
  height: 14px;
  font-size: 10px;
  color: white;
  text-transform: none;
  width: max-content;

  &.unselected {
    background-color: transparent;
    color: #003c7d;
  }

  span {
    cursor: pointer;
  }
}
