.cornerInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cornerGrid {
  height: 72px;
  width: 72px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.cornerGridItem {
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;

  .cornerIcon {
    border-top: 4px solid var(--grey-70);
    border-left: 4px solid var(--grey-70);
    width: 100%;
    height: 100%;

    &.focused {
      border-top-color: var(--prim-100);
      border-left-color: var(--prim-100);
    }
  }

  &:hover {
    background: var(--grey-border);
  }

  &:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    transform: rotate(90deg);
  }

  &:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    transform: rotate(270deg);
  }

  &:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    transform: rotate(180deg);
  }
}

.cornerValue {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
