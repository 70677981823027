.validateSubmitRow {
  border-bottom: 1px solid var(--grey-20);
  margin-bottom: 4px;
  padding-bottom: 6px;
}

.actionContainer {
  border-bottom: 1px solid var(--grey-20);
  margin-bottom: 5px;
  padding-bottom: 5px;

  .overflowIcon {
    cursor: pointer;
    fill: var(--grey-80);
    stroke: var(--grey-80);
    margin: 0 -5px;
  }

  .actionHeader {
    color: black;
  }

  :global(.row):not(.actionType) > :global(.col):nth-child(2) {
    padding-right: 17px;
  }
}

.addAction {
  cursor: pointer;
  color: var(--prim-100);
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 10px;
}
.errorMsg {
  padding: 0;
}

.paymentHeader {
  margin: 14px 0 0 0;
  color: black;
  font-size: 12px;
  padding-left: 6px;
}
.actionPropertiesGroup {
  padding: 5px 0 8px 8px;
  border-left: 4px solid var(--grey-20);

  .productLabel {
    font-weight: 600;
    padding-right: 0;
  }

  .fieldSelector {
    margin-left: 0;
    width: 100%;
    max-width: 150px;
  }
}
.checkboxLabel {
  display: flex;
}
.paymentCollection {
  padding: 2px 0 0 6px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-70);

  .paymentCollectionDescription {
    display: flex;
    flex-direction: column;
  }
  .pmLink {
    text-decoration: underline;
  }

  .editStripeButton {
    border: none;

    .editStripeIcon {
      stroke: var(--grey-80);
      width: 18px;
    }
  }
}
.noPadding {
  padding: 0 !important;
}
.noPaddingTop {
  padding: 0 0 6px 0;
}

.checkbox {
  padding-left: 6px;
}
