.buttonGroupAddContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  .addOption {
    width: 100px;
    padding: 3px 0;
  }
}
