.cellControlButton {
  cursor: pointer;
  background-color: var(--grey-30);
  color: var(--grey-80);
  padding: 4px 5px;
  width: 140px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;

  .iconContainer {
    margin-right: 5px;
  }

  &.wide {
    width: 170px;
  }
}

.errorCol {
  flex-direction: column;
}
.alignStart {
  align-items: flex-start;
}
