.panelOverlay {
  position: absolute;
  backdrop-filter: blur(2px);
  /* --grey-20 */
  background: rgba(255, 255, 255, 0.85);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .icon {
    stroke: var(--grey-60);
  }
  .message {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 60%;
    text-align: center;
    color: var(--grey-90);
  }
  .action {
    cursor: pointer;
    color: var(--prim-100);
    font-weight: 500;
    margin-left: 5px;
    margin-top: 10px;
  }
}
