.panel {
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &.blockView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    padding: 0 14px 0 10px;
  }

  &.listView {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .fieldContainer.listView {
    padding: 0 12px;
  }
}

.field {
  &:hover {
    background: var(--grey-20);
  }
}
