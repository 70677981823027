.flowContainer {
  padding: 15px;

  .editFlowButton {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: var(--prim-10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--prim-100);
    font-weight: 500;
    margin-bottom: 30px;

    svg {
      margin-right: 6px;
    }
  }

  .flowStepsContainer {
    position: relative;

    &.hasBranches {
      padding-right: 30px;
    }
  }
}
