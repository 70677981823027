.actionBar {
  display: flex;
  gap: 10px;

  .actionButton {
    display: flex;
    outline: none;
    border: 1px dashed var(--grey-50);
    border-radius: 6px;
    background-color: var(--grey-10);
    color: var(--grey-80);
    font-weight: 600;
    font-size: 1rem;
    padding: 6px 16px;
    align-items: center;
    gap: 5px;
    transition: background-color 0.1s ease-in-out;

    .actionButtonIcon {
      color: var(--grey-80);
    }

    &:hover {
      background-color: var(--grey-20);
    }
  }
}
