.ruleConditionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ruleConditionOperator {
    text-transform: capitalize;
    border-left: 2px solid var(--grey-50);
    padding-left: 7px;
    font-weight: 400;
    font-size: 0.9em;
    width: fit-content;
    margin-top: 10px;
  }
}

.ruleConditionContainer.editMode {
  .ruleConditionOperator:hover {
    cursor: pointer;
  }
}
