.formFieldEditorDialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  .editorBody {
    position: relative;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 0 -2px 4px rgba(114, 123, 153, 0.15);

    // Target global class defined in the library component
    :global(.nav-tabs) {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      border-top: 2px solid var(--grey-30);
    }
  }

  .deleteFooter {
    padding-top: 16px;
    padding-inline: 0.75rem;
    box-shadow: inset 0 1px 0 0 var(--grey-border);
    height: 60px;
    margin: 0;
  }

  .error {
    position: absolute;
    background: rgba(255, 0, 0, 0.62);
    color: white;
    padding: 15px;
    width: 100%;
    left: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
  }
}

.detailPanelTab {
  div[class~='col'] {
    padding: 6px 0;
    display: flex;
    align-items: center;
  }

  label {
    margin: 0;
  }
}

.fixedPanel {
  padding: 16px 16px 10px 16px;

  div[class~='col'] {
    padding: 6px 0;
  }

  .itemIcon {
    width: 20px;
    height: 20px;
  }
}

.labelType {
  background-color: #eff3f8;
  color: #414859;
  font-size: 12px;
  padding: 4px 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 5px;

  svg,
  img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
}

.idContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.id {
  padding-top: 1px;
}
