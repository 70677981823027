.breadcrumbsBar {
  height: 35px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey-60);

  .breadcrumbsBarControls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
  }
}

.viewportButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 35px;
  width: auto;
  border-right: 1px solid var(--grey-30);

  .viewportButton {
    height: 42px;
    width: 42px;
    margin-right: 1px;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    stroke: var(--grey-70);
    transition: all 250ms;
    border-radius: 4px;

    path {
      fill: var(--grey-60);
      stroke: none;
    }

    svg {
      z-index: 1;
    }

    &.active,
    &.activeMobile {
      cursor: default;
      position: relative;

      path {
        fill: var(--prim-100);
      }
    }

    &.active::before {
      content: ' ';
      position: absolute;
      top: 12%;
      left: 10%;
      width: 80%;
      height: 75%;
      background-color: var(--prim-10);
      border: 1px solid var(--prim-100);
      border-radius: 5px;
    }

    &.activeMobile::before {
      content: ' ';
      position: absolute;
      top: 12%;
      left: 5%;
      width: 70%;
      height: 75%;
      background-color: var(--prim-10);
      border: 1px solid var(--prim-100);
      border-radius: 5px;
    }
  }

  .viewportButton:last-child {
    margin-right: 0;
  }
}

.gridSwitch {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 2px;
}
