.fieldSelectorContainer {
  margin: 0 -40px;
  position: relative;

  .fieldSelectorSearch {
    position: absolute;
    z-index: 3;
    right: 15px;
    top: 10px;
    height: 30px;

    &.fieldSearchHiddenFields {
      top: 1px;
      right: 1px;
    }
  }
}

.fieldSelectorIDInput {
  border: none;
  outline: none;
  width: 100%;

  &span {
    cursor: pointer;
  }

  &input {
    cursor: text;
  }
}

.createHiddenFieldInput {
  display: flex;
}

.buttonContainer {
  margin-top: 20px;
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  &.back {
    justify-content: space-between;
  }
}

.negative {
  background-color: #fff !important;
  color: var(--prim-100) !important;
  border-color: var(--prim-100) !important;
  min-width: 100px;
  stroke: var(--prim-100);
}
