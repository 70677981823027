.fieldSelector {
  max-width: 90px;
}

.valueInput {
  max-width: 85px;
}

.validationSection {
  padding-top: 15px;
  border-bottom: solid 4px;
  border-color: var(--grey-10);
}

.validationsIntro {
  color: var(--grey-70);
}

.validationRuleName {
  font-weight: 700;
}

.validationRuleClauses {
  padding: 16px 0;

  .and {
    margin: 12px 0;
    padding-left: 8px;
    border-left: 2px solid var(--grey-50);
  }
  .validationRuleClause {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    flex-wrap: wrap;

    .logicRuleField {
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: #eff3f8;
      border-radius: 5px;
      padding: 4px 8px;

      .icon {
        width: 24px;
        height: 24px;
      }
      .field {
        font-weight: 600;
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .value {
      font-weight: 700;
    }
    .fieldItem {
      display: flex;
      align-items: center;
    }
  }
}

.validationErrMessageLabel {
  font-weight: 700;
  color: var(--grey-70);
  margin-bottom: 3px;
}
.validationErrMessage {
  font-weight: 400;
}
.validationsButton {
  margin-top: 24px;
  .icon {
    padding-bottom: 2px;
    margin-right: 4px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.overflowIcon {
  cursor: pointer;
  fill: var(--grey-80);
  stroke: var(--grey-80);
  margin: 0 -5px;
}
