.multiFieldDropdown {
  font-size: 16px;
  width: 100%;

  .multiFieldControl {
    width: 100%;
    min-height: 100%;
    box-shadow: none;
    position: relative;
    border-color: var(--grey-30);
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      border-color: #ced4da;
    }

    &.multiFieldControlFocused {
      color: var(--body-color);
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }

  .multiFieldContainer {
    height: 100%;
  }

  .multiFieldValueContainer {
    padding: 5px 8px;
    min-height: 48px;
  }

  .multiFieldMultiValue {
    border-radius: 5px;
    background-color: var(--grey-20);
    color: var(--grey-90);
    font-weight: bolder;
    padding-block: 1px;
    padding-left: 3px;
    margin: 5px 5px 5px 0;
  }

  .multiFieldMultiValueRemove {
    padding: 0 2px;
    color: var(--grey-60);
    font-size: 16px;
    border-radius: 4px;

    &:hover {
      background-color: transparent;
      color: var(--prim-100);
    }

    & svg {
      height: 18px;
      width: 18px;
    }
  }

  .multiFieldMenu {
    z-index: 1000;
  }

  .multiFieldMenuList {
    max-height: 200px;
    height: fit-content;
  }

  .multiFieldClearIndicator {
    & svg {
      height: 22px;
      width: 22px;
      color: var(--grey-60);
    }

    &:hover {
      cursor: pointer;

      & svg {
        color: var(--prim-100);
      }
    }
  }

  .multiFieldDropdownIndicator {
    & svg {
      color: var(--grey-60);
    }

    &:hover {
      cursor: pointer;

      & svg {
        color: var(--grey-70);
      }
    }
  }

  .multiFieldIndicatorSeparator {
    display: none;
  }

  .multiFieldIndicatorsContainer {
    margin-right: -2px;
  }
}
