.cardContainer {
  background-color: #fefcf7;
  border: 1px solid #f0bf12;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 8px;
  gap: 10px;
  color: #956900;

  .iconContainer {
    width: 20px;

    path {
      stroke: #f0bf12;
    }
  }
}
