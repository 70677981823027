.panelContainer {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  padding: 15px 0 0 0;

  & > .header {
    display: flex;
    gap: 10px;
    padding: 0 15px 15px 15px;
    border-bottom: 1px solid var(--grey-border);
    align-items: center;

    & > .label {
      height: fit-content;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    & > .backButton {
      width: 35px;
      height: 35px;
      flex-basis: 35px;
      flex-shrink: 0;

      outline: none;
      border: 1px solid var(--grey-border);
      border-radius: var(--input-radius);

      & > svg {
        stroke: var(--grey-80);
      }
    }

    & > select {
      flex: 1;
      height: 100%;
    }
  }

  & > .body {
    width: 100%;
    flex: 1;
    padding: 15px;

    overflow-y: scroll;

    & > .questionGroup {
      display: flex;
      flex-direction: column;
      gap: 15px;

      border: 1px solid var(--grey-border);
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;

      &.selected {
        border: 1px solid var(--prim-100);
      }

      & > .section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .label {
          display: flex;
          align-items: center;
          gap: 10px;

          &.clickable:hover {
            cursor: pointer;
          }

          & > .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 30px;
            height: 30px;

            border: 1px solid var(--grey-border);
            border-radius: 50%;
          }

          & > .text {
            font-weight: 500;
          }

          & > .actions {
            display: flex;
            flex: 1;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;

            & > button {
              margin-top: -2px;
            }
          }
        }

        & > .content {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .card {
          padding: 15px;
          border-radius: 10px;
          background-color: var(--grey-10);
          color: var(--grey-80);

          & > .title {
            text-transform: uppercase;
            font-size: 0.85rem;
            font-weight: 700;
            margin-bottom: 5px;
          }

          & .bold {
            font-weight: 500;
          }

          & .uppercase {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  & > .footer {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    padding: 15px;
    border-top: 1px solid var(--grey-border);
    transition: opacity 0.25s ease-in-out;
    opacity: 0;

    &.show {
      display: flex;
      opacity: 1;
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex: 1;
      width: 100%;
      color: white;
      background-color: var(--system-valid);
      font-weight: 500;
      font-size: 1.1rem;
      border-radius: 10px;
      border: none;
      transition: background-color 0.1s ease-in-out;

      &:disabled {
        background-color: var(--grey-50);
      }
    }

    &.updateFieldVals > button {
      background-color: orange;
    }
  }
}

.aiEntryField {
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  transition: background-color 0.1s ease-in-out;

  &.highlight {
    background-color: var(--prim-10);
    border: 1px solid var(--prim-20);
  }

  &.forceHighlight {
    border: 1px solid var(--prim-80);
  }

  & > .header {
    display: flex;
    align-items: center;

    padding: 10px;

    &:hover {
      cursor: pointer;
    }

    & > .text {
      flex: 1;
      color: var(--grey-80);

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    & > .icon {
      &.flip {
        transform: rotate(180deg);
      }

      svg {
        stroke: var(--grey-80);
      }
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    padding: 12px 10px 0 10px;

    &.separator {
      border-top: 1px solid var(--grey-border);
    }

    & > .header {
      color: var(--grey-80);
      font-weight: 500;
      font-size: 0.9em;
    }

    & > .values {
      margin-bottom: 7px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      & > .value {
        display: flex;
        flex: 1;
        gap: 8px;
        padding: 8px 4px;
        border: 1px solid rgba(0, 0, 0, 0);
        align-items: center;
        transition: background-color 0.1s ease-in-out;

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0);
        }

        &.editing {
          background-color: white !important;
          border: 1px solid var(--grey-border) !important;

          & > .text {
            flex: 1;
          }
        }

        &:hover,
        &.hovered,
        &.selected {
          border-radius: 4px;
        }

        &:hover,
        &.hovered {
          &:not(.selected) {
            background-color: var(--grey-20);
          }
        }

        &.selected {
          border: 1px solid var(--prim-100);
          background-color: var(--prim-10);
        }

        & > .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          border-radius: 4px;

          width: 20px;
          height: 20px;

          &.red {
            background-color: var(--prim-100);
          }

          &.grey {
            background-color: var(--grey-50);
          }
        }

        & > .text {
          flex: 1;
          color: var(--grey-90);

          &.tag {
            width: fit-content !important;
            flex-basis: fit-content;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 0.9rem;
            font-weight: 500;
            padding: 2px 5px;
            border-radius: 4px;
            background-color: var(--grey-20);
            color: var(--grey-70);
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
          }
        }

        & > .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          z-index: 10;
          gap: 5px;

          &.end {
            flex: 1;
          }

          & > div {
            position: relative;
            width: fit-content;

            &:hover {
              cursor: pointer;

              & > svg:not(.fill, .innerStroke) {
                stroke: var(--grey-80) !important;
              }

              & > svg.fill {
                & > path {
                  fill: var(--grey-80) !important;
                }
              }

              & > svg.innerStroke {
                & > path {
                  stroke: var(--grey-80) !important;
                }
              }
            }

            & > svg {
              height: 100%;
            }
          }
        }

        & > [contenteditable]:focus {
          outline: none;
        }
      }
    }
  }
}

.addValueModal {
  & > .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > .footer {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 25px;
    padding: 0 !important;

    & > button {
      margin: 0;
    }
  }
}

.pdfDocument {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  width: fit-content;
  height: fit-content;
}

.aiFieldPopover {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--grey-border);
  border-radius: 10px;
  width: 250px;

  & > .header {
    padding: 10px;
    border-bottom: 1px solid var(--grey-border);

    & > .title {
      font-weight: 500;
    }
  }

  & > .body {
    display: flex;
    flex-direction: column;

    & > .section {
      padding: 10px;
      border-bottom: 1px solid var(--grey-border);

      &:last-child {
        border-bottom: none !important;
      }

      & > .label {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9em;
        margin-bottom: 2px;
      }

      & > .content {
        &.list {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        & > .value {
          padding: 2px 8px;
          background-color: rgba(235, 239, 242, 0.7);
          border-radius: 6px;

          & > .label {
            color: var(--grey-80);
            font-weight: 500;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}

.entryValueDisplay {
  display: flex;
  gap: 5px;
  width: fit-content;

  & > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 4px;

    width: 16px;
    height: 16px;

    &.red {
      background-color: var(--prim-100);
    }

    &.grey {
      background-color: var(--grey-50);
    }
  }

  & > .text {
    color: var(--grey-90);

    &.tag {
      width: fit-content;
      font-size: 0.9em;
      font-weight: 500;
      padding: 1px 5px;
      border-radius: 4px;
      background-color: var(--grey-20);
      color: var(--grey-70);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }
}

.zoomable {
  position: relative;
  width: 100%;
  height: 100%;

  & > .zoomableContent {
    display: flex;
    justify-content: center;
  }

  & > .zoomableControls {
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 40px;
    gap: 5px;
    z-index: 10;

    & > div {
      border: 1px solid var(--grey-border);
      border-radius: 7px;
    }

    & > .zoomableDisplay {
      display: flex;
      background-color: #fff;
      color: var(--grey-80);
      justify-content: center;
      align-items: center;
      width: 65px;
      transition: border-color 0.1s ease-in-out;

      & > span {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }

      &.highlight {
        border: 1px solid var(--prim-100);
        background-color: var(--prim-10);
      }

      &.clickable {
        &:hover {
          cursor: pointer;
        }
      }
    }

    & > .zoomableButtons {
      display: flex;

      & > button {
        padding: 7px 15px;
        outline: none;
        border: none;
        background-color: #fff;
        color: var(--grey-80);

        &:first-child {
          border-radius: 7px 0 0 7px;
        }

        &:last-child {
          border-radius: 0 7px 7px 0;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--grey-20);
        }
      }
    }
  }
}

.emailViewerContainer {
  position: relative;
  margin-bottom: 25px;
  width: 800px;
  z-index: 10;

  .emailDocument {
    .emailPage {
      background-color: #fff;
      padding: 50px;
      overflow-x: auto;
    }
  }

  .extractionEntry {
    .highlight {
      position: inline-block;
      background-color: rgba(226, 98, 110, 0.5);
      border: 2px solid var(--prim-100);
      width: fit-content;
      height: fit-content;
    }

    &:hover > .highlight {
      border: 2px solid var(--prim-100) !important;
      background-color: rgba(226, 98, 110, 0.5) !important;
      cursor: pointer;
    }

    &.grey > .highlight {
      background-color: rgba(226, 98, 110, 0.2);
      border: 2px solid rgba(226, 98, 110, 0.5);
    }
  }
}

.pdfViewerContainer {
  position: relative;
  margin-bottom: 25px;
  z-index: 10;

  .dataLayer {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    .dataLayerPage {
      position: relative;

      & .extractionEntry {
        position: absolute;
        width: 20px;
        height: 20px;
        transition: opacity 0.1s ease-in-out;
        opacity: 1;

        & > .highlight {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          background-color: rgba(226, 98, 110, 0.5);
          border: 2px solid var(--prim-100);
        }

        &:hover > .highlight {
          border: 2px solid var(--prim-100) !important;
          background-color: rgba(226, 98, 110, 0.5) !important;
          cursor: pointer;
        }

        &.grey > .highlight {
          background-color: rgba(226, 98, 110, 0.2);
          border: 2px solid rgba(226, 98, 110, 0.5);
        }

        &.hide {
          opacity: 0;

          &:hover > .highlight {
            cursor: default !important;
          }
        }
      }
    }
  }
}

.errorContainer {
  display: flex;
  align-items: center;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}
