.propertyLabelContainer {
  font-size: 12px;
  font-family: var(--font);
  color: var(--grey-70);
  font-weight: 500;
  height: 100%;

  &.centered {
    display: flex;
    align-items: center;
  }

  &.indented {
    margin-left: 8px;
  }

  .propertyLabel {
    width: fit-content;
    border-radius: 4px;
    position: relative;
    padding: 4px 6px 3px 6px;
    display: flex;
    margin-right: 4px;
  }

  .highlighted {
    color: #2c80ff;
    background: #e1edff;
    cursor: pointer;

    &.svgLabel {
      svg {
        fill: #2c80ff;
      }

      .menu {
        svg {
          fill: none;
        }
      }
    }
  }

  .menu {
    position: absolute;
    width: max-content;
    background: var(--grey-90);
    border-radius: 6px;
    color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &.topLeft {
      bottom: 100%;
      right: 0;
    }

    &.topRight {
      bottom: 100%;
      left: 0;
    }

    &.bottomLeft {
      top: 100%;
      right: 0;
    }

    &.bottomRight {
      top: 100%;
      left: 0;
    }

    strong {
      font-weight: 800;
    }

    .menuSection {
      padding: 12px 32px 12px 12px;
      border-bottom: 1px solid var(--grey-70);
      display: flex;
      flex-direction: column;
      font-family: var(--font);
      font-weight: 300;
      gap: 8px;

      &:last-child {
        border: none;
      }
    }

    .menuSectionLabel {
      font-weight: 600;
      text-transform: uppercase;
      color: var(--grey-60);
      font-size: smaller;
    }

    .menuItem,
    .menuItemStroke {
      display: flex;
      gap: 8px;
      align-items: center;
      transition: all 100ms;
    }

    .menuItem {
      &:hover {
        color: var(--prim-100);

        svg * {
          fill: var(--prim-100);
        }
      }
    }

    .menuItemStroke {
      margin-left: 3px;

      svg {
        stroke: white;
      }

      &:hover {
        color: var(--prim-100);

        svg {
          stroke: var(--prim-100);
        }
      }
    }
  }
}
