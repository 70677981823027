.closeConfirm {
  position: absolute;
  backdrop-filter: blur(4px);
  /* --grey-20 */
  background: rgba(235, 239, 242, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  padding: 12px 16px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;

  .closeCancel {
    align-self: flex-end;
  }
  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
    }
    .message {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 60%;
      text-align: center;
    }
    .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 12px;

      .noButton {
        background-color: transparent;
        color: var(--prim-100) !important;
        border: 1px solid var(--prim-100);
        &:hover {
          color: var(--button-primary-hover) !important;
          border-color: var(--button-primary-hover);
        }
      }
    }
  }
}

.logicRuleDelete {
  cursor: pointer;
  transition: transform 0.1s ease-out;
}
