.page {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  background-color: #f6f7fa;
}

.page-content {
  overflow-y: auto;
  max-height: 100vh;
  width: 100vw;

  &.no-vertical-scrollbar {
    overflow-y: hidden;
  }

  &.always-vertical-scrollbar {
    scrollbar-gutter: stable;
  }
}

.page-head {
  font-size: 2rem;
  font-weight: 600;
  font-family: var(--font);
  margin-top: 50px;
  margin-bottom: 10px;
}

.page-head-description {
  color: #9fa2b4;
  font-family: var(--font);
}

.page-loader {
  display: block;
  margin: 100px auto auto;
}

.form-detail-page {
  width: 100%;
  height: 100%;
}

.tooltip-hide {
  display: none !important;
  opacity: 0 !important;
}

.badge {
  background: var(--grey-20);
  margin-right: 0.5rem;
}

.form-settings-page {
  height: 100%;
  width: 100%;
  background: white;
  overflow-y: auto;

  .settings-page {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--main-nav-height));

    h1.settings-header {
      margin-bottom: 2rem;
      margin-left: 18px;
    }
  }
}

.field-group {
  margin-bottom: 2rem;

  .form-label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--grey-90);
    display: flex;
  }

  .form-control {
    border-radius: 8px;
    height: 50px;
    font-size: 15px;
    padding: 10px 20px;
    outline: none;

    &:not(:disabled) {
      resize: none;

      &:focus {
        resize: vertical;
      }
    }
  }
}
