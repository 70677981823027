/* Global Styles and Definitions */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    @apply inline;
  }

  input[type='radio'],
  input[type='checkbox'] {
    accent-color: var(--prim-100);
  }

  button {
    // button resets
    @apply outline-none border-solid border-0 bg-transparent cursor-pointer text-inherit m-0 p-0 appearance-none disabled:cursor-default;
  }

  [hidden] {
    display: none !important;
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

@layer components {
  // Legacy tabs used in places like the designer side panel
  .legacyTabsList {
    @apply bg-transparent w-full h-full flex border-y;

    .legacyTabsTriggerFlex {
      @apply text-center border-0 m-0 rounded-none py-4 px-[10px] font-bold data-[state=active]:text-primary data-[state=active]:shadow-[inset_0_-3px_0_0_currentColor] flex-1 text-black;
    }

    .legacyTabsTrigger {
      @apply text-center border-0 m-0 rounded-none min-w-[120px] py-4 px-[10px] font-bold data-[state=active]:text-primary data-[state=active]:shadow-[inset_0_-3px_0_0_currentColor] text-black;
    }
  }

  .legacyTabsContent {
    div[class~='col'] {
      @apply px-0 py-[6px] flex items-center;
    }

    label {
      @apply m-0;
    }
  }

  // Legacy tabs used in Form Rules page
  .legacyRulesTabsList {
    @apply bg-transparent w-fit gap-[6px] flex border-b-0;

    .legacyRulesTabsTrigger {
      position: relative;
      overflow: visible;
      @apply rounded-t-xl border-grey-300 py-3 px-8 -mb-px bg-grey-200 font-bold text-grey-700 border border-b-0 data-[state=active]:text-primary data-[state=active]:bg-white border-solid;

      &[data-state='active'] {
        z-index: 3;
        &:first-child {
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: white;
          transform: translateY(1px);
          z-index: 3;
        }
      }

      &:first-child {
        z-index: 1;
        &:not([data-state='active']):before {
          content: '';
          border-left: 1px solid #dce0e7;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: -1;
          @apply bg-grey-200 -translate-x-px;
        }
      }
    }
  }

  .legacyRulesTabsContent {
    z-index: 1;
    position: relative;
    @apply bg-white border border-solid border-grey-300 h-full rounded-r-xl rounded-b-xl last:rounded-l-xl;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  padding: 0;
  margin: 0;
  background-color: transparent;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--prim-100);
}

a:hover {
  text-decoration: none;
  color: var(--prim-100);
}

// TODO move
.animate-children > *,
.animate-children *,
.animate-children {
  transition: all 0.3s ease-in-out;
}

.testing > *,
.testing *,
.testing {
  transform: translate3d(0);
}

.form-control {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: var(--input-radius);
  color: var(--body-color);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  outline: none;

  &::placeholder {
    color: #757575;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    border-color: #86b7fe !important;
  }

  &:disabled {
    background-color: #efefef;
    color: #21252988;
  }
}

:root {
  font-size: 10pt;
  --font: 'Axiforma', sans-serif;

  --main-nav-height: 55px;
  --editor-sidebar-width: 350px;
  --selection-panel-width: 210px;
  --editor-connections-sidebar-width: 500px;

  --text-light: #888888;
  --button-primary-hover: #cb4e5a;
  --button-neutral-hover: #fbfdff;
  --button-negative-hover: #d13232;

  --bg: #f5f5f5;
  --bg-dark: #eee;
  --bg-light: #fff;

  --red: #ff5e63;
  --gray: #666666;
  --green: #28a745;
  --grey-border: #dee2e6;
  --green-border: #0ebe74;
  --red-border: #e84343;

  --system-valid: #0ebe74;

  --body-color: #212529;
  --input-radius: 0.375rem;

  --grey-90: #333849;
  --grey-80: #414859;
  --grey-70: #6c7589;
  --grey-60: #a3aaba;
  --grey-50: #bdc2ce;
  --grey-40: #cfd4dd;
  --grey-30: #dbdfe8;
  --grey-20: #ebeff2;
  --grey-10: #f4f5f8;

  --prim-100: #e2626e;
  --prim-80: #efa7ad;
  --prim-60: #eda3a1;
  --prim-40: #f2bab9;
  --prim-20: #f5cbcb;
  --prim-10: #fbeaea;

  --error: #d6504a;
  --validation-pass-light: #f8fefb;
  --validation-error-light: #fff3f4;
}

body {
  font-family: 'Axiforma', sans-serif;
  background-color: var(--bg);
  overflow-x: hidden;
}

button {
  outline: unset !important;
}

.inp {
  height: 42px;
  background-color: var(--bg-dark);
  outline: none;
  font-size: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 6px;
}

.inp::placeholder,
.select::placeholder {
  color: #757575;
}

.inp:disabled::placeholder,
.select:disabled::placeholder {
  opacity: 0.8;
}

.select {
  height: 42px;
  border-radius: 6px;
  border: none;
  background-color: var(--bg-dark);
  padding: 0 10px 0 10px;
}

.inp:disabled,
.select:disabled {
  background-color: var(--bg);
}

.tooltip {
  z-index: 9999999;
}

.ql-editor {
  height: 200px !important;
}

.dialog-form-input {
  margin-bottom: 5px;
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 100;
  src: local('Axiforma'), url('../fonts/AxiformaThinfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 200;
  src: local('Axiforma'),
    url('../fonts/AxiformaLightfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 300;
  src: local('Axiforma'), url('../fonts/AxiformaBookfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 400;
  src: local('Axiforma'),
    url('../fonts/AxiformaRegularfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 500;
  src: local('Axiforma'),
    url('../fonts/AxiformaMediumfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 600;
  src: local('Axiforma'),
    url('../fonts/AxiformaSemiBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 700;
  src: local('Axiforma'), url('../fonts/AxiformaBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 800;
  src: local('Axiforma'),
    url('../fonts/AxiformaExtraBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 900;
  src: local('Axiforma'),
    url('../fonts/AxiformaBlackfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 1000;
  src: local('Axiforma'),
    url('../fonts/AxiformaHeavyfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Basis Grotesque';
  font-weight: 400;
  src: local('Basis Grotesque'),
    url('../fonts/BasisGrotesqueRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Basis Grotesque (Off-White)';
  font-weight: 400;
  src: local('Basis Grotesque (Off-White)'),
    url('../fonts/BasisGrotesqueOffWhite.woff2') format('woff2');
}

@font-face {
  font-family: 'calistoga';
  src: local('Calistoga'),
    url(../fonts/Calistoga-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "'Circular std'";
  src: local('Circular std'), url(../fonts/Circular-Std.otf) format('truetype');
}

@font-face {
  font-family: 'Chronicle Display';
  font-weight: 900;
  src: local('Chronicle Display'),
    url('../fonts/Chronicle Display Black.woff') format('woff');
}

@font-face {
  font-family: 'Chronicle Display';
  font-weight: 600;
  src: local('Chronicle Display'),
    url('../fonts/Chronicle Display Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Chronicle Display';
  font-weight: 300;
  src: local('Chronicle Display'),
    url('../fonts/Chronicle Display Roman.woff') format('woff');
}

@font-face {
  font-family: 'Chronicle Display';
  font-weight: 100;
  font-style: italic;
  src: local('Chronicle Display'),
    url('../fonts/Chronicle Display Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 900;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextBlack.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 900;
  font-style: italic;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextBlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 700;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 700;
  font-style: italic;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 300;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 300;
  font-style: italic;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 500;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 500;
  font-style: italic;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextMediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 400;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Domaine Sans Text';
  font-weight: 400;
  font-style: italic;
  src: local('Domaine Sans Text'),
    url('../fonts/DomaineSansTextRegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 900;
  src: local('Graphik'), url('../fonts/Graphik Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 700;
  src: local('Chronicle Display'),
    url('../fonts/Graphik Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  src: local('Graphik'), url('../fonts/Graphik Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'FF Sero Std';
  font-weight: 300;
  src: local('FF Sero Std'), url('../fonts/FF Sero Std.woff') format('woff');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 200;
  src: local('PolySans'), url('../fonts/PolySans-Slim.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 200;
  font-style: italic;
  src: local('PolySans'),
    url('../fonts/PolySans-SlimItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 400;
  src: local('PolySans'), url('../fonts/PolySans-Neutral.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 400;
  font-style: italic;
  src: local('PolySans'),
    url('../fonts/PolySans-NeutralItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 600;
  src: local('PolySans'), url('../fonts/PolySans-Median.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 600;
  font-style: italic;
  src: local('PolySans'),
    url('../fonts/PolySans-MedianItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 800;
  src: local('PolySans'), url('../fonts/PolySans-Bulky.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 800;
  font-style: italic;
  src: local('PolySans'),
    url('../fonts/PolySans-BulkyItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 200;
  src: local('PrimaryFont'), url('../fonts/PrimaryFontL.woff') format('woff');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 400;
  src: local('PrimaryFont'), url('../fonts/PrimaryFont.woff') format('woff');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 600;
  src: local('PrimaryFont'), url('../fonts/PrimaryFontM.woff') format('woff');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 800;
  src: local('PrimaryFont'), url('../fonts/PrimaryFontB.woff') format('woff');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 600;
  src: local('PrimaryFont'), url('../fonts/PrimaryFontM.woff') format('woff');
}

@font-face {
  font-family: 'PrimaryFont';
  font-weight: 800;
  src: local('PrimaryFont'), url('../fonts/PrimaryFontB.woff') format('woff');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 800;
  src: local('Sansation'), url('../fonts/Sansation-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 800;
  font-style: italic;
  src: local('Sansation'),
    url('../fonts/Sansation-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 600;
  src: local('Sansation'),
    url('../fonts/Sansation-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 600;
  font-style: italic;
  src: local('Sansation'),
    url('../fonts/Sansation-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 400;
  src: local('Sansation'),
    url('../fonts/Sansation-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-weight: 400;
  font-style: italic;
  src: local('Sansation'),
    url('../fonts/Sansation-LightItalic.ttf') format('truetype');
}

.rollout-popover {
  z-index: 999999999 !important;
}

[data-radix-popper-content-wrapper] {
  z-index: 9999999999 !important;
}
