$primary-control-color: rgba(2, 123, 255, 1);
$darker-primary-control-color: rgba(2, 123, 255, 0.3);

:export {
  primaryColor: $primary-control-color;
  darkerPrimaryColor: $darker-primary-control-color;
}

.controlLayerContainer {
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;
  contain: size style layout;
}

.controlContainer {
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  z-index: 55;
}

.addControlWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
