.logicSubtitle {
  font-size: 15px;
  color: var(--grey-80);
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  .fieldKey {
    font-weight: 700;
  }

  .behaviorSelector {
    border-radius: 5px;
    background-color: var(--grey-10);
    box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
    width: 80px;
  }
}

.logicScroller {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 24px 0;

  .logicContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .logicSection {
      box-sizing: border-box;
      border: 1px solid var(--grey-30);
      border-radius: 6px;
      padding: 12px 16px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        width: 100%;

        .logicLabelContainer {
          display: flex;

          .logicRuleName {
            font-weight: 700;
            font-size: 18px;
          }
        }
      }

      .ruleGroup {
        display: flex;
        flex-direction: column;

        .and {
          margin: 10px 0;
          padding-left: 8px;
          border-left: 2px solid var(--grey-50);
        }

        /* comparisonRule */
      }

      .addCondition {
        margin-top: 8px;

        &:not(:hover) {
          color: var(--grey-70);
          svg {
            stroke: var(--grey-70);
            fill: var(--grey-70);
          }
        }
      }

      .hr {
        color: var(--grey-30);
        width: calc(100% + 32px); /* Probably a better way to do this? */
        margin: 1rem -16px;
      }

      .errMessageLabel {
        font-weight: 700;
        color: var(--grey-70);
        margin-bottom: 4px;
      }
    }
    .logicButton {
      max-width: 30%;
    }
  }
}

.comparisonRule {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;

  .leftContainer {
    position: relative;

    .fieldSelector {
      max-width: 300px;
      min-width: 120px;
      margin: 0;
      padding-right: 20px;
      &.locked {
        background-color: var(--grey-70);
      }
    }
  }
  .rightContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    .operatorSelectorContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .operatorSelector {
        border-radius: 5px;
        background-color: var(--grey-10);
        box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
      }
      .hiddenOperatorSizer {
        height: 0; /* Don't want to see it */
        visibility: hidden;
      }
    }

    .valueContainer {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      .valueInput {
        box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
        width: 8rem;
        margin: 0;
        padding-right: 20px;
        display: inline-block;

        &:hover {
          border-color: #007bff;
        }
      }
      .valueField {
        margin: 0;
        padding-right: 20px;
      }
    }
    .addButton {
      box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
      border-radius: 16px;
      border: 1px solid var(--grey-30);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none !important;

      &:hover {
        background: var(--grey-30);
        box-shadow: none;

        svg {
          stroke: white;
          fill: white;
        }
      }
    }
  }
  .overflowIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
  }
}

.deleteRuleConfirm {
  position: absolute;
  backdrop-filter: blur(4px);
  /* --grey-20 */
  background: rgba(235, 239, 242, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  padding: 12px 16px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;

  .ruleDeleteCancel {
    align-self: flex-end;
  }
  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
    }
    .message {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 60%;
      text-align: center;
    }
    .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .noButton {
        background-color: transparent;
        color: var(--prim-100) !important;
        border: 1px solid var(--prim-100);
        &:hover {
          color: var(--button-primary-hover) !important;
          border-color: var(--button-primary-hover);
        }
      }
    }
  }
}

.logicRuleDelete {
  cursor: pointer;
  transition: transform 0.1s ease-out;
}
