.ruleBuilderContainer {
  font-size: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  border-radius: 14px;
  overflow: hidden;
  padding: 0 16px;
  padding-bottom: 16px;

  .ruleBuilderHeader {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    & div:first-child {
      flex: 1;
      font-size: 1.2em;
      font-weight: 600;
    }
  }
}
