.dimensionInputContainer {
  padding: 6px 0;
  display: flex;

  & > div {
    margin-left: 10px;
  }

  & > div:first-child {
    margin-left: 0;
  }
}

.dimensionInputLabelContainer {
  display: inline-flex;
  flex: 0;

  &:first-child {
    flex: 1;
    justify-content: end;
  }

  & > * {
    text-align: right;
    width: 100%;
  }
}

.dimensionInputLabel {
  justify-content: end;
}

.dimensionInput {
  display: inline-flex;
  flex: 1;
}

.dimensionInputUtility {
  align-self: center;
}

.dimensionInputUtilityIcon {
  width: 25px;
  justify-content: center;
  transform: rotate(-45deg);

  &:not(.disabled):hover {
    cursor: pointer;
  }
}

.disabled {
  cursor: not-allowed;
}
