.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  user-select: none;

  .sideNav {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    background-color: #f1f1f1;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #ddd;
    color: rgba(65, 72, 89, 1);

    .title {
      padding: 16px;
      margin-left: 6px;
      font-size: 18px;
      font-weight: 500;
    }

    .sideNavButton {
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 6px;
      text-decoration: none;
      font-size: 14px;
      color: rgba(65, 73, 89, 1);
      display: flex;
      align-items: center;
      margin: 4px 12px;
      line-height: 24px;
      border: 1px solid transparent;
      min-height: 32px;
      max-height: 32px;

      &.outlined {
        &.active {
          border: 1px solid rgba(226, 98, 110, 1);
          background-color: rgba(226, 98, 110, 0.05);
          font-weight: 600;
        }
      }

      .navItem {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      svg {
        margin-right: 8px;
      }

      &:hover {
        background-color: rgba(231, 234, 240, 1);
      }

      &.active {
        background-color: rgba(231, 234, 240, 1);
      }
    }
  }

  .page {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
