.navRules {
  background-color: white;
  grid-column: 2;
  grid-row: 1;
  z-index: 2;
  box-shadow: -5px 0 5px #eee;
  height: 100%;
  overflow-y: scroll;
  position: relative;

  &.inPanel {
    box-shadow: none;
  }

  .navDirectionContainer {
    padding: 20px;

    &.navDirectionContainerReverse {
      border-top: 1px solid lightgrey;
    }

    .navDirectionHeader {
      margin-bottom: 20px;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrowIcon {
        margin-bottom: 3px;

        svg {
          stroke: var(--grey-60);
        }
      }

      .navDesignerEntry {
        background-color: var(--grey-20);
        border-radius: 100px;
        padding: 5px 12px;
        font-size: 14px;
        font-weight: 500;
        color: var(--grey-80);
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
}

.ruleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border: 1px solid lightgrey;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 16px;

  .richTextSpan {
    white-space: pre-wrap;

    &:focus {
      outline: none;
    }

    &.richTextHighlight {
      background-color: rgb(186, 215, 251);
    }
  }

  .ruleTextField {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
  }

  .ruleDelete {
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
  }

  .deleteIcon {
    cursor: pointer;
    margin-left: 3px;

    svg {
      stroke: var(--grey-70);
    }

    &:hover {
      svg {
        stroke: var(--grey-80);
      }
    }
  }

  .ruleRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    white-space: nowrap;

    &.condition {
      align-items: baseline;
    }
  }
  .ruleLine {
    position: absolute;
    background-color: var(--grey-40);
    left: 3px;
    bottom: -12px;
    height: calc(100% - 16px);
    width: 2px;

    &.textRuleLine {
      height: 46px;
      left: -47px;
    }
  }

  .ruleErrorRow {
    margin-top: 10px;
    color: red;
  }

  .addRow {
    margin-bottom: 0;
    cursor: pointer;
    color: var(--grey-70);

    .addNewIcon {
      margin-right: 3px;
      padding-bottom: 3px;
      fill: var(--grey-70);
    }

    &:hover {
      color: var(--grey-80);

      .addNewIcon {
        fill: var(--grey-80);
      }
    }
  }

  .textRow {
    display: inline-block;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 10px;
    background-color: rgb(238, 238, 238);
    cursor: text;
    position: relative;
  }
}

.addNavigationRule {
  height: 40px;
}

.ruleDropdown {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  width: auto;
}

.addNewRuleIcon {
  margin-right: 3px;
  padding-bottom: 2px;
  fill: black;
}

.ruleTarget {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed grey;
  border-radius: 6px;
  background-color: var(--grey-20);
  cursor: pointer;

  &:not([disabled]):not(.selected):hover {
    background-color: var(--grey-30);
  }
  &[disabled] {
    cursor: not-allowed;
  }
}

.fieldTarget {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 5px;
  margin-right: 5px;
  height: 32px;
  min-width: 60px;
  max-width: 240px;

  &.fieldTargetError {
    background-color: var(--prim-10);
  }

  &.selected {
    color: white;
    background-color: black;
    border: none;
  }

  &.long {
    max-width: 220px;
  }
}

.fieldSpan {
  padding-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
