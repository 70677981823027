.stepLoadButton {
  margin-left: 0 !important;
  height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.elementSelectorContainer {
  overflow-y: auto;
  overflow-x: hidden;
  outline: 1px solid black;
  margin-top: 20px;
  margin-bottom: 20px;

  .elementCover {
    z-index: 10;

    &.selectable {
      border: 2px solid transparent;
      cursor: pointer;
      transition: all 100ms;

      &:hover,
      &.selected {
        background-color: var(--prim-10);
        border-color: var(--prim-100);
      }
    }

    > div,
    > span {
      pointer-events: none;
    }
  }
}

.elementSelectorIcon {
  width: 25px;
  height: 20px;
  margin-right: 3px;
  padding-bottom: 3px;
}
