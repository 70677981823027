.container {
  width: 104%;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .row {
    width: 100%;
    height: 140px;
    margin-bottom: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .card {
    width: 195px;
    height: 100%;
    outline: 1px solid var(--grey-40);
    border-radius: 4px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .cardDelete {
      visibility: hidden;
    }

    &:hover {
      outline-width: 2px;

      .cardDelete {
        visibility: visible;
      }
    }

    &.selected {
      outline-width: 2px;
      outline-color: var(--prim-100);
    }
  }
}

.cardDeleteContainer {
  cursor: pointer;

  &:hover {
    .cardDelete {
      stroke: var(--prim-100);
    }
  }
}

.thumbnailLabel {
  height: 28px;
  width: 100%;
  padding: 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;

  .thumbnailLabelText {
    width: 194px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    padding-top: 3px;
  }

  .thumbnailDelete {
    zoom: 1.3;
    width: 15px;
  }
}
