.settingsLabelContainer {
  display: flex;
  flex-direction: row;

  .roleEditIcon {
    margin-top: auto;
  }
}

.settingsLabel {
  color: var(--grey-80);
  font-weight: 500;
}

.settingsSublabel {
  color: var(--grey-70);
  display: flex;
}

.sectionSeparator {
  height: 1px;
  width: 100%;
  background-color: var(--grey-30);

  &.spacedNarrow {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &.spaced {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.upperSection {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 30px;

  .upperSectionLeft {
    display: flex;
    flex-direction: row;

    .monthlyUsersSection {
      background-color: white;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      padding: 15px;
      justify-content: space-around;

      .monthlyUsersValue {
        color: var(--prim-100);
        font-size: 24px;
        font-weight: 600;
      }
    }

    .environmentSection {
      margin-right: 30px;

      .apiKeySection {
        margin-top: 4px;
        display: flex;
        align-items: center;

        .settingsSublabel {
          width: 100px;
        }

        .apiKeyValue {
          padding: 5px 12px;
          background-color: white;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--grey-40);
        }
      }
    }
  }
}

.settingsTabs {
  width: 450px;
  background-color: white;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;

  .settingsTab {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.settingsField.settingsField {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid var(--grey-30);
  color: var(--body-color);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  width: 278px;
  min-height: 48px;
  border-radius: 8px;
  margin-right: 30px;

  &.email,
  &.taxId {
    width: 200px;
  }
}

.settingsMultiField.settingsMultiField {
  width: 278px;
  min-height: 48px;
  border-radius: 8px;
  margin-right: 30px;
}

.settingsMultiFieldControl.settingsMultiFieldControl {
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  color: var(--body-color);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.permissionsToggle {
  margin-top: 15px;
}

.lowerSection {
  background-color: white;
  width: 100%;
  padding: 0 40px 30px 40px;
  position: relative;

  .roleEditIcon {
    cursor: pointer;
    stroke: var(--grey-90);
    margin-left: 10px;
  }

  .inviteButton {
    width: 120px;
    position: absolute;
    right: 30px;
    top: 30px;
    border: 1px solid var(--prim-100);

    &:before {
      // Hide pseudo-element from border-button
      width: 0;
    }
  }

  .editButton {
    border: 1px solid var(--prim-100);
    height: 30px;
    width: 50px;
    display: inline-block;
    padding: 0;
  }

  .settingsLabel {
    display: flex;
    padding-top: 30px;
  }

  .userListSeparator {
    display: flex;
    flex-direction: row;
    gap: 250px;
  }

  .teamFieldSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    .teamFieldSave {
      color: var(--prim-100);
      font-size: 14px;
      cursor: pointer;
    }
  }

  .billingFieldSetting {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    gap: 8px;

    .billingFieldSaveButton {
      border: none;
      color: var(--prim-100);
      font-size: 14px;
      cursor: pointer;
    }
    .buttonGroup {
      display: flex;
      gap: 20px;
    }
  }
  .taxFields {
    display: flex;
    .taxIdType {
      width: 320px;
    }
  }

  .teamRow {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .teamControls {
      display: flex;
      flex-direction: row;
      align-items: center;

      .teamEmail {
        margin-left: 15px;
        width: 300px;
        color: var(--grey-80);
        display: flex;
        align-content: center;
        flex-wrap: wrap;
      }

      .teamRole {
        width: 300px;
      }

      .teamGroups {
        max-width: 450px;
      }
    }

    .teamDeleteIcon {
      cursor: pointer;
    }
  }

  .planSection {
    display: flex;
    flex-direction: row;
    padding-top: 30px;

    .activitySection {
      margin-right: 150px;
      display: flex;
      flex-direction: column;
      padding: 0 0 30px 0;

      .subsectionLabel {
        font-weight: 600;
        font-size: 18px;
      }

      .settingsLabel {
        margin-top: 18px;
        margin-bottom: 0.2rem;
      }
      .totalSubmissions {
        color: var(--grey-90);
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
      }

      .submissionSummarySection {
        margin: 20px 0;

        .submissionItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 17px;

          .count {
            color: var(--grey-90);
            font-weight: 600;
            font-size: 18px;
          }
          .form {
            color: var(--grey-80);
            background: var(--grey-10);
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
            padding: 2px 4px 1px;
          }
        }
      }

      .viewAll {
        color: var(--prim-100);
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }

      .loadingSubmissions {
        display: flex;
        padding: 8px 0;
      }
      .loadingSubmissionsSpinner {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  .billingSection {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .billMetricsSection {
      margin-right: 90px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .billAmount {
        font-size: 18px;
        margin-top: 0;
      }
      .overageButton {
        width: 160px;
      }
    }

    .paymentMethod {
      padding-top: 20px;
    }
  }
}

.sectionTitle {
  color: #333849;
  font-weight: 500;
  padding-bottom: 8px;
  padding-top: 10px;
  font-size: medium;
}

.sectionParagraph {
  color: #333849;
  font-size: small;
}

.roleStyles {
  width: 400px;
}

.domainsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
