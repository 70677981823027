.actionContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;

  &.editMode.invalid {
    border: 1px solid #ff9699;
    background-color: #fff8f8;
    border-radius: 10px;
    padding: 6px;
    padding-right: 10px;
    margin-left: -34px;
    margin-top: -6px;

    &.loneAction {
      margin-left: 0;
    }
  }

  .actionErrorIcon {
    margin-left: 4px;
  }
}

.actionContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.fieldSelector {
  margin: 0 !important;
  background-color: var(--grey-20) !important;
  color: var(--body-color) !important;

  & svg {
    fill: var(--body-color) !important;
  }
}

.actionActions {
  display: flex;
  gap: 10px;
  height: fit-content;

  & svg {
    path {
      fill: var(--grey-60);
    }

    &:hover {
      cursor: pointer;

      path {
        fill: var(--grey-80);
      }
    }
  }
}
.overflowMenu {
  cursor: pointer;
}

.fieldWrapper {
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 32px;
}

.fieldWrapper > div {
  width: 100%;
}

.ruleBuilderInput {
  min-width: 200px;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  gap: 8px;

  align-items: center;
  background-color: #fff;
  border-radius: var(--input-radius);
  border: 1px solid var(--grey-border);
  line-height: 1.5;
  align-self: stretch;
  padding: 0 0.75rem;

  & > label {
    font-size: 1rem;
    text-wrap: nowrap;
  }
}

.multiSelectInput {
  min-width: 350px;

  & > div > div {
    padding: 0 !important;
    padding-left: 5px !important;
  }
}
