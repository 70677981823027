.tag {
  background-color: var(--grey-40);
  border-radius: 4px;
  width: fit-content;
  padding: 4px 7px;
  color: var(--grey-80);
}

.tagsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  max-width: 100%;
}
