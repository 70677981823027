.planCard {
  --plan-card-width: 242px;
  position: relative;

  .card {
    min-width: var(--plan-card-width);
    max-width: var(--plan-card-width);
    height: 278px;
    color: var(--grey-90);
    background: var(--grey-10);
    padding: 1.2rem;
    line-height: 1.8;

    .planName {
      font-size: 18px;
      font-weight: 600;
    }
    .planCost {
      height: 9px;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.5;
      color: var(--grey-70);
    }
  }
  .planCardOverlay {
    width: var(--plan-card-width);
    position: absolute;
    bottom: 0;
    background-color: var(--prim-100);
    color: white;
    height: 32px;
    line-height: 32px;
    border-radius: 0 0 4px 4px;
    text-align: center;
    opacity: 0;
    cursor: pointer;
  }
  .overlayVisible {
    cursor: pointer;
    opacity: 1;
    transition: all 0.4s;
  }
}

.planDetails {
  list-style: none;
  line-height: 1.3;
  font-size: 13px;
  max-width: 16rem;
  margin-top: 10px;
  padding-left: 2rem;

  .point::before {
    color: var(--prim-100);
    font-weight: bold;
    display: inline-block;
    font-size: 18px;
    height: 0;
    width: 1.5rem;
    margin-left: -1.5rem;
    position: relative;
    top: 2px;
  }

  .keyPoint::before {
    content: '\2713';
  }
  .additionalPoint::before {
    content: '+';
  }
  .more {
    color: var(--prim-100);
  }
}

.planCardActive {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: default !important;
}

.planSelector {
  display: flex;
  gap: 42px;
  flex-wrap: wrap;

  .chosenPlan {
    display: flex;
    flex-direction: column;

    .chosenPlanIndicator {
      display: flex;
      align-items: center;
      gap: 6px;
      background: var(--grey-80);
      color: white;
      border-radius: 4px 4px 0 0;
      height: 32px;
      font-weight: 700;
      padding-left: 12px;
    }
  }

  .browsePlans {
    display: flex;
    flex-direction: column;
    flex: 3;

    .browsePlansHeader {
      height: 32px;
      font-size: 16px;
      color: var(--grey-70);
    }
    .availablePlans {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }
}

.paymentFieldSection {
  width: 355px;
  min-height: 110px; /* try to make height consistent */

  .methodButton {
    width: 120px;
  }

  .paymentCardLast4 {
    margin-bottom: 20px;
  }

  .paymentField {
    height: 47px;
    border-radius: 8px;
    border: 1px solid var(--grey-40);
    padding: 15px 0 0 15px;
    margin-bottom: 20px;
  }

  .paymentButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .paymentDelete {
      margin-left: 20px;
      color: var(--error);
      cursor: pointer;
    }
  }
}

.cta {
  display: flex;
  justify-content: space-between;
  margin-right: 36px;
  .ctaLeft {
    display: flex;
  }
  .ctaBody {
    margin: 0 8px 0 12px;
    line-height: 24px;
  }
  .ctaTitle {
    color: var(--grey-90);
    font-weight: 600;
    font-size: 14px;
  }
  .ctaMessage {
    color: var(--grey-70);
    font-weight: 400;
    font-size: 12px;
  }
  .ctaButton {
    margin: 4px;
    padding: 0.375rem 0.75rem;
  }
}

.ctaPanel {
  z-index: 100;
  position: relative;
  display: block;
  background-color: var(--bg-light);
  padding: 8px 16px;
  min-height: 70px;
  overflow: auto;
  transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  border: 1px solid var(--grey-30);
  box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
  border-radius: 8px;
}
.panel-xsm {
  width: 400px;
}

.panel-sm {
  width: 500px;
}

.panel-lg {
  width: 700px;
}

.panel-xlg {
  width: 900px;
}

.panelClose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 8px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
  outline: none;
}

.panelClose * {
  font-size: 1.8rem !important;
}
