.ruleClauseContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.error {
    border: 1px solid #ff9699;
    background-color: #fff8f8;
    border-radius: 14px;
    padding: 20px;
    width: fit-content;
  }

  .ruleClauseTitle {
    display: flex;
    align-items: center;
    gap: 5px;

    .ruleClauseTitleActions {
      display: flex;
      align-items: center;

      & svg {
        path {
          fill: var(--grey-60);
        }

        &:hover {
          cursor: pointer;

          path {
            fill: var(--grey-80);
          }
        }
      }
    }

    .ruleClauseTitleText::before {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  .ruleClauseCondition {
    margin-bottom: 25px;
  }

  .ruleClauseConditionActions {
    margin-bottom: 10px;
  }

  .ruleClauseActions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &.indent {
      margin-left: 50px;
    }
  }

  .ruleClauseSpecialActions {
    margin-top: 25px;
  }
}

.ruleClauseContainer.ruleClauseWhen:first-child {
  & .ruleClauseTitleActions {
    display: none;
  }
}

.ruleClauseContainer:not(.ruleClauseWhen):only-child {
  & .ruleClauseTitle {
    display: none;
  }
}

.ruleClauseContainer:not(.ruleClauseWhen):only-child {
  & .ruleClauseTitleText::before {
    content: '' !important;
  }

  & .ruleClauseActions {
    margin-left: 0 !important;
  }
}

.ruleClauseContainer.ruleClauseWhen:first-child {
  & .ruleClauseTitleText::before {
    content: 'When';
  }

  & .ruleClauseActions {
    margin-left: 50px;
  }
}

.ruleClauseContainer.ruleClauseWhen {
  & .ruleClauseTitleText::before {
    content: 'Else If';
  }

  & .ruleClauseActions {
    margin-left: 50px;
  }
}

.ruleClauseContainer:not(.ruleClauseWhen):last-child {
  & .ruleClauseTitleText::before {
    content: 'Else';
  }

  & .ruleClauseActions {
    margin-left: 50px;
  }
}
