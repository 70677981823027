.mobileGatePage {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  background-color: #fff;
}

.mobileGatePageContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 80px;
  padding: 115px 50px 0 50px;
}

.mobileGatePageContent {
  font-size: 16px;

  & > * {
    margin-bottom: 20px;
  }

  & > h1 {
    font-size: 1.375em;
    font-weight: 600;
    color: #20242b;
  }

  & > p {
    font-size: 0.875em;
    font-weight: 400;
    color: #414859;
  }
}
