.overlay {
  z-index: 999999999;
  box-sizing: border-box;
}

.textarea.floating {
  overflow-y: auto;
  z-index: 9999999;
  /* 
   * CODE SCOUT:
   * Make formfield box-shadow a css variable that can be composed with other box-shadows
   * eg. box-shadow: var(--formfield-box-shadow), 0 12px 20px 0 rgba(39, 67, 108, 0.08);
   */

  // add floating effect shadow to the normal formfield box-shadow
  box-shadow: 0 0 0 2px rgba(84, 148, 243, 0.23),
    0 12px 20px 0 rgba(39, 67, 108, 0.08);
}

.textarea {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  resize: none;
  height: fit-content;

  width: 100%;
  max-width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  /* Custom scrollbar */
  & {
    scrollbar-gutter: stable;
    cursor: auto;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(1, 1, 52, 0.06);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(1, 8, 45, 0.27);
  }
  @media (hover: hover) {
    :where(&:not(:disabled))::-webkit-scrollbar-thumb:hover {
      background-color: rgba(1, 5, 27, 0.45);
    }
  }
}
