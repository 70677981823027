.field-icon-toggle {
  border: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  cursor: pointer;

  &:hover {
    border-color: var(--grey-70);
    z-index: 10;
  }
}

#editor-panel.editor-canvas.field-options-display-input.field-options-display-input.field-options-display-input {
  margin-top: -5px !important;
}

.field-options-end {
  border-radius: 0 4px 4px 0;

  svg {
    stroke: var(--grey-80);
  }
}

.col-vertical {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
}
