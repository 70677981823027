.collapsibleSection {
  position: relative;

  &.separator {
    border-top: 1px solid #dee2e6;
  }

  .header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    color: #000;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    background: #fff;
    border: 0;
    border-radius: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    padding: 14px;

    &.resetHeader {
      background-color: transparent;
      border-top: 0;
    }
    &:not(.collapsible) {
      cursor: default;
    }
    &.collapsible {
      cursor: pointer;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none !important;
        background-color: #fff !important;
      }
      &.resetHeader {
        &:focus,
        &:active {
          outline: none;
          border-top: 0 !important;
          color: black !important;
          background: none !important;
        }
      }
    }

    &.resetHeader {
      cursor: pointer;
    }

    .collapsibleLabelSection {
      cursor: pointer;

      .title {
        display: flex;
        gap: 10px;
        flex: 1;
        font-weight: 600;
      }

      .label {
        color: var(--prim-100);
        font-size: 10px;
      }
    }

    .expandIndicator {
      margin-left: auto;
      transition: all 0.33s ease-in-out;
      display: flex;
      cursor: pointer;

      svg {
        stroke: currentColor;
      }
    }
  }

  &.addBg {
    margin-top: 2px;
  }
  &.highlighted {
    border-color: var(--prim-100);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .content {
    transition: all 0.33s ease-in-out;
    padding: 0 14px 14px 14px;

    &.resetHeader {
      padding: 0;
    }
  }

  &:not(&.expanded) {
    .expandIndicator {
      transform: none;
    }
  }

  &.expanded {
    .expandIndicator {
      transform: rotateX(180deg);
    }
  }
}

.sectionHeader {
  font-size: 20px;
  font-weight: 600;
  padding: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom-width: 1px;
}
