.container {
  outline: 1px solid var(--grey-30);
  box-shadow: 0 1px 2px rgba(43, 54, 71, 0.1);
  border-radius: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grey-10);
  padding-inline: 8px;
  margin-left: 4px;
  gap: 8px;

  .text {
    font-weight: 400;
    font-size: 14px;
    color: var(--grey-90);
  }
}
