.ruleBuilderHeaderStatus {
  border: 1px solid #ff9699;
  background-color: #fff8f8;
  color: var(--body-color);
  padding: 9px 16px;
  font-size: 0.85em;
  border-radius: 6px;
  margin-right: 10px;
}

.ruleBuilderHeaderActions {
  display: flex;
  gap: 10px;
  position: absolute;
  top: -45px;
  right: 0;
}

.savedChangesButton {
  display: flex;
  align-items: center;
  gap: 7px;

  &:hover {
    background-color: var(--grey-20) !important;
    cursor: default;
  }

  & svg {
    margin-top: 4px;
  }
}

.emptyRuleBuilder {
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h3 {
    font-size: 1em;
    margin-bottom: 20px;
  }

  & .emptyRuleBuilderActions {
    display: flex;
    gap: 10px;

    & button {
      outline: none;
      border: none;
      padding: 10px 14px;
      border-radius: 6px;
      font-size: 0.85em;
      transition: background-color 0.1s ease-in-out;
    }

    & button:first-child {
      background-color: var(--grey-20);

      &:hover {
        background-color: var(--grey-40);
      }
    }

    & button:last-child {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: var(--prim-100);
      color: #fff;

      & svg {
        fill: #fff;
        stroke: #fff;
      }

      &:hover {
        background-color: var(--button-primary-hover);
      }
    }
  }
}
