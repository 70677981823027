.downloadCTA {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 300;
  color: var(--grey-90);
  width: 250px;

  .downloadFileLink {
    cursor: pointer;
    color: rgb(49, 124, 246);
    text-decoration: underline;
  }
}

.configLabel {
  margin-top: 14px;
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 300;
  color: var(--grey-80);
}

.existingFile {
  height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid var(--grey-30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-80);
  font-size: 13px;
  font-weight: 300;

  .existingFileName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

.richFileInput {
  cursor: pointer;
  height: 83px;
  width: 100%;
  background-color: var(--grey-10);
  border-radius: 5px;
  border: 1px dashed var(--grey-30);
  display: flex;
  align-items: center;
  justify-content: center;

  .hiddenFileInput {
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    opacity: 0;
  }
}
