.cardContainer {
  background-color: var(--grey-20);
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 1px solid var(--grey-60);

  .payloadContainer {
    display: flex;
    gap: 10px;
  }

  .iconContainer {
    width: 20px;
  }

  .closeIcon {
    cursor: pointer;
    height: 18px;
    width: 18px;

    path {
      fill: var(--grey-60);
    }
  }
}
