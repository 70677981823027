.numberInput {
  min-width: 125px !important;
}

.multiFieldValueContainer {
  padding: 0 5px !important;
}

.expression {
  display: flex;
  flex-direction: column;
  width: 100%;
  .expressionInputContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    padding-right: 20px;
    border: 1px solid var(--grey-border);
    border-radius: var(--input-radius);

    &.focus {
      color: var(--body-color);
      background-color: white;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

    .expressionInput {
      height: 32px;
      border: none;

      &:focus {
        box-shadow: none;
      }
    }

    .caretIcon {
      position: absolute;
      right: 4px;
      top: 8px;
      width: 18px;
      height: 18px;
      stroke: #414859;
      cursor: pointer;
    }
  }

  &.error {
    margin-bottom: -22px;
  }
  .errorMsg {
    color: var(--red);
    font-size: 14px;
    overflow-y: visible;
    text-wrap: nowrap;
    max-width: 100%;
  }
}

.codeCompletionMenu {
  max-height: 400px;
}
