.breadcrumbContainer {
  --arrow-size: 17px;
  --neg-arrow-size: calc(-1 * var(---arrow-size));
  font-size: 12px;
  color: var(--grey-70);

  .breadcrumb {
    cursor: pointer;
    height: 34px;
    color: var(--grey-90);
    text-decoration: none;
    padding: 9px 0 0 25px;
    background: white;
    position: relative;
    display: block;
    float: left;

    .breadcrumbLabel {
      font-weight: 500;
    }
  }

  .breadcrumb:first-child {
    padding-left: 8px;
  }

  .ellipse {
    cursor: default !important;
  }

  .breadcrumb:not(:last-child):hover .breadcrumbLabel {
    text-decoration: underline;
    color: var(--prim-100);
  }

  .ellipse .breadcrumbLabel {
    color: var(--grey-90) !important;
    text-decoration: none !important;
  }

  .breadcrumb:last-child {
    color: var(--grey-70);
    cursor: default;
  }

  .breadcrumb:not(:last-child)::after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid transparent;
    border-left: var(--arrow-size) solid white;
    position: absolute;
    top: 0%;
    margin-top: var(--neg-arrow-size);
    left: 100%;
    z-index: 2;
  }

  .breadcrumb:not(:last-child)::before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid transparent;
    border-left: var(--arrow-size) solid var(--grey-30);
    position: absolute;
    top: 0%;
    margin-top: var(--neg-arrow-size);
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
}
