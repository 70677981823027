.imageFieldContainer {
  color: var(--text-light);
  width: 100%;
  // Works well with column-sm-3
  max-width: 240px;

  .imageFieldCard {
    margin-top: 5px;
    height: 170px;
    outline: 1px solid var(--grey-30);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:not(.onlyDisplay):hover {
      outline-width: 2px;
    }

    .imageFieldGrid {
      display: grid;
      grid-template-rows: auto 30px;
      justify-content: center;

      .imageFieldCardIcon {
        grid-row: 1/2;
        align-self: center;
      }

      .imageFieldCardLabel {
        grid-row: 2/3;
      }
    }

    &.onlyDisplay {
      height: min-content;
      background-color: var(--grey-10);
      cursor: default;
    }
  }
}

.thumbnailContainer {
  background-color: var(--grey-10);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .thumbnail {
    max-width: 100%;
    max-height: 100%;
  }
}
