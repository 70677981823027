.colorPickerWrap {
  display: flex;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  gap: 1px;
  height: 32px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:has(> .colorInput:focus) {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    border-color: #86b7fe;
  }

  .colorInput {
    position: relative;
    width: 100%;
    border: 0;
    text-transform: uppercase;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;

    &:focus {
      outline: 0;
    }
  }
}
