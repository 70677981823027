$canvas-styles: (
  'formCanvasPadding': 66px
);
:export {
  @each $key, $value in $canvas-styles {
    #{unquote($key)}: $value;
  }
}

.renderedStepContainer {
  width: calc(
    100% - var(--selection-panel-width) - var(--editor-sidebar-width)
  );
  display: grid;
  grid-template-rows: auto;
  overflow: auto;
  background: var(--grey-10);
  .step {
    grid-area: 1 / 2 / -1 / 3;
    display: flex;
    justify-content: center;
    padding: map-get($canvas-styles, 'formCanvasPadding') 0
      map-get($canvas-styles, 'formCanvasPadding') 0;
    width: 100%;
    height: 100%;
    background: var(--grey-10);
  }
}

.videoCover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: inherit;
}

.gigWrapper {
  display: flex;
  min-height: 100%;
  min-width: 100%;
}

.editorCanvas {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: width 0.15s ease-in-out, max-width 0.15s ease-in-out,
    height 0.15s ease-in-out, max-height 0.15s ease-in-out !important;

  &.unconstrained {
    max-width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;

    &.wFill {
      width: 100vw;
    }

    &.hFill {
      height: 100vh;
    }

    &.wFit {
      width: fit-content;
    }

    &.hFit {
      height: fit-content;
    }
  }

  &.mobile {
    width: 478px;
    max-width: 478px;
    height: 100%;
    z-index: 1;

    &.wFit {
      width: fit-content;
    }

    &.hFit {
      height: fit-content;
    }
  }

  &.desktop {
    width: 100%;
    max-width: 100%;
    height: 100%;

    &.wFit {
      width: fit-content;
    }

    &.hFit {
      height: fit-content;
    }
  }
}

.editorCanvasContainer {
  width: 100%;
  box-sizing: border-box;
  overflow: scroll;
  scrollbar-gutter: stable;

  .editorCanvasFitContent {
    min-height: fit-content;
    min-width: fit-content;
  }
}

.editorCanvasContainer::-webkit-scrollbar-track {
  background: #cb0d0d;
}

.canvasContainer {
  padding: 12px;
  border-left: 1px solid var(--grey-60);
  // Needed to make the border occupy the whole canvas if the form
  // doesn't overflow. 90px is the combined height of the nav bar +
  // breadcrumbs bar
  height: calc(100vh - 90px);
}

.editorCanvasWrapper {
  position: relative;
  flex: 1 0 100%;
  min-height: fit-content;
  min-width: fit-content;
  height: 100%;
  width: 100%;
}

.editorCanvasBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
