.ruleExpressionContainer {
  display: flex;
  align-items: center;
  width: fit-content;

  &.invalid {
    border-color: #ff9699 !important;
    background-color: #fff8f8;
  }

  &.editMode {
    padding: 15px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }

  .ruleExpressionErrorIcon {
    margin-right: 10px;
  }

  .ruleExpressionContent {
    flex: 1;
  }

  .ruleExpressionActions {
    display: flex;
    gap: 10px;
    height: fit-content;
    margin-left: 15px;

    & svg {
      path {
        fill: var(--grey-60);
      }

      &:hover {
        cursor: pointer;

        path {
          fill: var(--grey-80);
        }
      }
    }
  }
}

.conditionalExpressionContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.fieldSelector {
  background-color: var(--grey-20) !important;
  color: var(--body-color) !important;
}

.overflowIcon {
  fill: var(--body-color) !important;
  stroke: var(--body-color) !important;
}
