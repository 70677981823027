.settingsContainer {
  margin: 10px 0 20px 18px;
  width: 600px;

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    .titleText {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: var(--grey-70);
      white-space: nowrap;
      width: fit-content;
      margin-right: 20px;
    }

    .separator {
      border-bottom: 1px solid var(--grey-50);
      width: 100%;
      height: 1px;
      overflow: hidden;
    }
  }
}
