.tooltip {
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;

  svg {
    fill: none !important;
  }

  &.warning {
    svg path {
      stroke: red;
    }
  }

  &:hover:not(.warning) {
    svg path {
      stroke: var(--grey-80);
    }
  }
}
