.emailHelperText {
  color: var(--grey-70);
  font-size: 12px;
  margin-bottom: 10px;

  &.spacing {
    margin-bottom: 15px;
  }

  &.collaborativeVariable {
    margin-top: 5px;
    margin-left: 30px;
  }
}

.emailLabel {
  margin-top: 10px;
  display: flex;
}

.emailInputGroup {
  border-bottom: 1px solid var(--grey-60);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.addEmailButton {
  border: 1px solid var(--prim-100);
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 20px !important;
}

.removeEmailButton {
  border: 1px solid var(--prim-100);
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 20px !important;
}
