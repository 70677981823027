.imagePropertiesDisplayField {
  display: inline-flex;
  flex-grow: 1;

  & > * {
    margin-right: 15px;
  }
}

.imagePropertiesOpacityField {
  display: inline-flex;
  flex-grow: 0;
  width: 100%;
}

.imagePropertiesScaleField {
  min-width: 60px;
}
