.enableLabel {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--grey-90);
  display: flex;
}

.collaboratorSection {
  padding: 20px 0;
  border-bottom: 1px solid var(--grey-30);
  width: 100%;

  .delete {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--grey-20);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .label {
      padding-top: 0;
    }
  }

  .controlsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    min-height: 45px;

    .controlLabel {
      width: 140px;
    }
  }
}

.controlField {
  width: 100%;
  min-height: 45px;
}

.inviteMessageSection {
  margin-top: 20px;
}

.inviteLabel {
  margin-bottom: 5px;
}

.inviteEmailSection {
  margin-top: 5px;
  margin-bottom: 15px;
}

.addCollaboratorButton {
  border: 2px solid var(--prim-100);
  color: var(--prim-100);
  width: max-content;
  margin: 20px 0 10px 0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.error {
  color: var(--error);
  font-size: 16px;
  margin-top: 5px;
}
