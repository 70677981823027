.pageContainer {
  display: flex;
  flex-direction: row;
  height: 100%;

  .pageSidebar {
    .pageSidebarContent {
      position: absolute;
      top: var(--main-nav-height);
      left: 0;
      height: calc(100vh - var(--main-nav-height));
      background-color: white;
      border-right: 1px solid var(--grey-border);
      z-index: 10;
    }

    .pageSidebarPlaceholder {
      position: relative;
      height: 100vh;
    }

    .pageSidebarContent,
    .pageSidebarPlaceholder {
      width: 425px;
    }
  }

  .pageContent {
    flex: 1;
    height: calc(100vh - var(--main-nav-height));
    margin-top: var(--main-nav-height);
    overflow: scroll;
    margin-top: var(--main-nav-height);

    &.centerContent {
      display: flex;
      justify-content: center;
    }
  }
}

.viewer {
  position: relative;
  margin-top: 25px;

  .viewerTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

.dataLayerPushdown {
  height: 150px;
}

.dataLayerError {
  position: fixed;
  bottom: 50px;
  width: 800px;
  z-index: 10;

  & > .content {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;

    & > .dataLayerErrorCard {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }
  }
}
