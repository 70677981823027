.lockIconContainer {
  position: absolute;
  top: 50%;
  left: -10.7px;
  transform: translateY(-50%);
  z-index: 1;
  width: 20px;
  height: 20px;

  .lockIcon {
    width: 20px;
    height: 20px;
  }
}
