.page {
  width: 100%;
  padding-top: 28px;
  flex-shrink: 1;
  overflow-y: scroll;
  position: relative;

  .content {
    padding: 0 40px;
    padding-bottom: 4px;
  }

  .block {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
  }

  .alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    text-align: center;

    background-color: var(--grey-10);

    &.error {
      background-color: var(--prim-10);
    }

    & .title {
      font-size: 1rem;
      font-weight: 600;
      color: var(--grey-90);
    }

    & .body {
      color: var(--grey-70);
    }
  }

  .field {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .label {
    display: flex;
    color: var(--grey-90);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 7px;
    gap: 5px;
    align-items: center;
    line-height: 16px;

    .icon {
      height: 16px;
      width: 16px;

      & svg path {
        stroke: var(--grey-90);
      }
    }
  }

  .help {
    margin-top: 7px;
    color: var(--grey-80);
    font-size: 0.9rem;
  }
}

.tooltip {
  z-index: 99999999 !important;
}

.tableHeader {
  display: flex;
  padding: 0 20px;
  color: var(--grey-90);
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey-20);
  margin-bottom: 10px;

  & div {
    display: flex;
    flex: 1;
    align-items: center;

    & .label {
      text-transform: uppercase;
      background-color: var(--grey-20);
      color: var(--grey-70);
      border-radius: 4px;
      font-size: 0.8rem;
      margin-bottom: 0 !important;
      padding: 2px 4px;
      margin-left: 10px;
    }
  }
}

.tableContent {
  display: flex;
  padding: 0 20px;

  & > div {
    flex: 1;
    width: 100%;

    &:first-child {
      margin-right: 5px;
    }
  }
}

#selectAPIConnectorPage {
  .actions {
    display: flex;
    gap: 10px;
  }
}

#mapResponsePage {
  .fieldSelector {
    margin-top: -10px;
    margin-bottom: -40px;

    .fieldSelectorTable {
      margin: 0;
      width: 100%;
    }
  }
}
