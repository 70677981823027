.radioInput {
  color: var(--grey-90);

  .radioInputLabel {
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .radioCheck {
    margin-right: 10px;
    &:checked {
      background-color: var(--prim-100);
      border-color: var(--prim-100);
    }

    &:focus {
      outline: none;
      border-color: var(--prim-40);
    }
  }
}
