.borderInputGrid {
  display: grid;
  grid-template-columns: 116px 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 116px;
}

.borderInputPreview {
  grid-row: 1 / -1;
  grid-column: 1 / 2;
  padding: 18px;
  display: grid;
  grid-template-columns: 14px 1fr 14px;
  grid-template-rows: 14px 1fr 14px;
}

.border {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 2px solid var(--grey-border);

  &:hover {
    border-color: var(--grey-70);
  }

  &.focused {
    border-color: var(--prim-100);
  }

  &:nth-child(1) {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    clip-path: polygon(14px 100%, calc(100% - 14px) 100%, 100% 0, 0 0);
  }

  &:nth-child(2) {
    grid-row: 1 / -1;
    grid-column: 3 / 4;
    clip-path: polygon(0% 14px, 100% 0, 100% 100%, 0 calc(100% - 14px));
  }

  &:nth-child(3) {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    clip-path: polygon(14px 0, calc(100% - 14px) 0, 100% 100%, 0 100%);
  }

  &:nth-child(4) {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
    clip-path: polygon(0 0, 100% 14px, 100% calc(100% - 14px), 0 100%);
  }
}

.borderInputValueLabel {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:nth-child(2) {
    grid-row: 1 / 2;
  }

  &:nth-child(3) {
    grid-row: 2 / 3;
  }

  &:nth-child(4) {
    grid-row: 3 / 4;
  }
}

.borderInputValue {
  grid-column: 3 / 4;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    grid-row: 1 / 2;
  }

  &:nth-child(2) {
    grid-row: 2 / 3;
  }

  &:nth-child(3) {
    grid-row: 3 / 4;
  }
}
