.apiConnectorInput {
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  color: var(--grey-80);
  font-size: 1rem;
  display: flex;
  gap: 7px;
}

.httpMethodBadge {
  font-size: 0.75rem;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;

  &.primary {
    background-color: #d1e7ff;
    color: #017bff;
  }

  &.secondary {
    background-color: var(--grey-30);
    color: var(--grey-80);
  }
}
