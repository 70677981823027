.startingStepControl {
  margin-top: 10px;
}

.mobileLayoutSection {
  padding: 8px 24px 24px;
  font-size: 12px;
  color: var(--grey-70);
  font-weight: 500;

  .title {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    margin-bottom: 10px;
  }

  .icon {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: var(--grey-20);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    height: 16px;
  }

  .description {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    font-size: 9px;
    font-weight: 400;
    height: 16px;
  }

  .button {
    grid-column: 1 / -1;
    grid-row: 5 / 6;
  }
}

.helpLinkSection {
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: var(--grey-10);

    .iconContainer {
      background-color: var(--prim-100);
    }
  }

  .iconContainer {
    height: 38px;
    width: 38px;
    background-color: var(--grey-80);
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 26px;
      width: 26px;
    }

    .svg {
      stroke: white;
    }
  }

  .title {
    font-weight: 700;
  }

  .description {
    font-size: 12px;
    color: var(--grey-70);
  }
}

.dragAndDropHint {
  width: 100%;
  background-color: var(--grey-20);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;

  .hint {
    width: 100%;
    color: var(--grey-70);
    font-size: 10px;
  }

  .description {
    text-align: center;
    width: 220px;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
