.filterExpression {
  display: flex;
  align-items: center;
  gap: 8px;

  &.field {
    width: 448px;
  }

  .dateInput {
    border: 1px solid var(--grey-border);
    border-radius: 4px;
    height: 32px;
  }
}

.datePicker {
  z-index: 100;
}
