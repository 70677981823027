.dialog-form-action {
  margin-top: 32px;
  border: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
