.elementSection {
  padding: 24px;
  position: relative;
  transition: all 250ms;
  background-color: white;
  border: 2px solid transparent;
  border-radius: 3px;

  &.element-section-basic,
  &.element-section-field {
    border-color: var(--grey-40);
    box-shadow: none;

    .header {
      position: relative;
      padding-bottom: 26px;
      top: -8px;
      left: -16px;
    }
  }

  &.element-section-basic {
    border-bottom: none;
  }

  &.element-section-field {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.element-section-text_area > div > div {
    height: auto;
  }

  &.overflow {
    overflow-x: auto;
  }

  &.dark {
    background-color: var(--grey-90);
    color-scheme: dark;

    .elementSection {
      background-color: transparent;
    }

    .header {
      color: white;
    }

    .title {
      color: white;
    }

    .darkSectionHeader {
      color: white;
    }
  }

  .header {
    position: absolute;
    top: -16px;
    left: 16px;
    padding: 0 8px;
    font-size: 18px;
    z-index: 2;
  }

  .headingWrapper {
    position: relative;
    left: -8px;
    margin-bottom: 24px;
  }

  .title {
    flex: 1;
    font-weight: 600;
    font-size: 14px;
  }

  .label {
    color: var(--prim-100);
    font-size: 10px;
  }
}

.highlighted {
  border-color: var(--prim-100) !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.highlightable {
  &:hover {
    border-color: var(--prim-100);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}
