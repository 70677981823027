.translationsInitialInstructions {
  width: 550px;
  margin-bottom: 15px;
  font-weight: 500;
}

.translationsContainer {
  width: 100%;
  border: 1px solid var(--grey-30);
  border-radius: 8px;
  font-weight: 300;

  .translationContainer {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-30);
    }

    .iconsContainer {
      display: flex;
      align-items: center;

      .iconContainer {
        cursor: pointer;
        margin: 7px;

        &.disabled {
          cursor: default;
        }
      }

      .spacer {
        width: 68px;
      }
    }
  }
}

.addTranslationToggle {
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  padding: 7px 11px;
  margin-top: 13px;
  background-color: var(--grey-30);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--grey-90);
}

.noMarginLeft {
  margin-left: 0;
}
