.toastContainer {
  position: fixed;
  z-index: 999999999;
  width: 500px;
  bottom: 40px;
  margin: 0 calc(50% - 500px / 2);
  display: flex;
  flex-direction: column;
  align-items: center;

  .toast {
    width: 500px;
    max-width: 500px;
    display: grid !important;
    grid-template-rows: 12px min-content 12px;
    grid-template-columns: 64px 1fr min-content min-content;
    background: white;
    color: var(--grey-70);
    border: 1px solid var(--grey-50);
    border-radius: 6px;
    margin-top: 10px;

    &.miniToast {
      width: fit-content;
      grid-template-columns: min-content max-content min-content min-content;

      .toastText {
        padding: 0 20px;
      }
    }
  }

  .toastIcon {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--grey-60);

    svg {
      height: 20px;
      width: 20px;
      stroke: var(--grey-60);

      path {
        stroke: var(--grey-60);
      }
    }
  }

  .toastText {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--grey-90);
  }

  .toastAction {
    width: 64px;
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 1rem;
    color: var(--prim-100);
    cursor: pointer;
  }

  .toastClose {
    width: 48px;
    grid-row: 1 / -1;
    grid-column: -2 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
  }

  .smallToast {
    max-width: 200px;
    text-align: center;
    position: relative;
    margin: 0 auto;
  }
}
