.fieldIdColumn {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 5px;

  .fieldIdInputContainer {
    width: 195px;
    display: flex;
    position: relative;

    .fieldIdInputLinked {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .fieldIdInputChevron {
      border: 1px solid var(--grey-40);
      border-radius: 0 0.25rem 0.25rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: black;
      aspect-ratio: 1;
    }
  }

  .fieldIdToggle {
    width: 32px;
    height: 32px;
    margin-left: 10px;
    border: 1px solid var(--grey-40);
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.linked {
      border-color: var(--prim-100);

      path {
        fill: var(--prim-100);
      }
    }

    &:hover:not(.linked):not(.disabled) {
      border-color: black;
    }

    &.disabled {
      cursor: default !important;
    }
  }
}
