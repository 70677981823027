.operand {
  font-weight: 600;

  &.bold {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;

    padding: 8px 10px;
    background-color: var(--grey-20);
    border-radius: 5px;
    font-weight: 500;
    border: 1px solid var(--grey-20);

    & *:first-child:not(:only-child) {
      width: 24px;
    }

    & *:last-child {
      flex: 1;
    }
  }

  &.invalid {
    background-color: #fff8f8;
    border: 1px solid #ff9699;
  }
}
