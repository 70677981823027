.aiExtractionsListPage {
  padding: 0 30px;
}

.modalInput {
  margin-bottom: 20px;
  min-height: 45px;
}

.labelContainer {
  display: flex;
  width: max-content;
}

.switch {
  margin-bottom: 20px;
}

.addGroupButton {
  border: 2px solid var(--prim-100);
  color: var(--prim-100);
  width: max-content;
  margin: 0 0 60px 0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.error {
  background-color: var(--prim-10);
  border: 1px solid var(--prim-100);
  padding: 10px;
  border-radius: 6px;

  display: flex;
  gap: 10px;

  align-items: center;

  & > .icon {
    margin: 0 5px;

    & > svg path {
      fill: var(--prim-100);
    }
  }

  & > .content {
    flex: 1;
    color: var(--grey-80);

    & > .title {
      font-weight: bold;
    }

    & > .message {
      font-size: 0.9rem;
    }
  }
}
