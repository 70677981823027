.containerGridMode {
  &::before {
    content: '';
    border: 1px solid #dbdfe8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
  }
}

.canDrop {
  &::before {
    background-color: rgba(0, 0, 0, 0.08) !important;
    z-index: 1;
  }
}
