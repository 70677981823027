.dimensionsInput {
  display: flex;
  flex-wrap: nowrap;
  padding: 6px 0;

  & > div {
    flex: 1;
    padding-left: 0;
  }
}

.dimensionsInputField {
  min-width: 75px;
  flex-basis: 50px;
}

.dimensionsInputLinkButton {
  margin-left: 10px;
}
