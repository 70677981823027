.pdfDocument {
  width: min-content;
  margin: 0 auto;

  .pdfPage {
    margin-bottom: 20px;
  }
}
.fieldContainer {
  position: absolute;
}

.dragging {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragging svg {
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
}

.fillableFieldLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 50%;

  & svg {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
  }
}

.fillableField {
  z-index: 2;
  background-color: rgba(163, 163, 163, 0.1);
  color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;

  cursor: pointer;

  outline: 1px solid var(--outline-color);
  --outline-color: rgb(193, 193, 193);

  &.changeable {
    --outline-color: rgb(121, 176, 235);
    background-color: rgba(121, 176, 235, 0.1);
  }
  &.selected {
    --outline-color: rgb(2, 123, 255);
    outline-width: 2px;
    background-color: rgba(2, 123, 255, 0.1);
    &.changeable {
      cursor: move;
    }
    & .fillableFieldLabel {
      opacity: 100%;
    }
  }
  &.filled {
    --outline-color: rgb(40, 147, 87);
    background-color: rgba(40, 147, 87, 0.1);
  }
  &.error {
    background-color: rgba(226, 98, 110, 0.15);
    --outline-color: rgb(226, 98, 110);
  }

  &:hover {
    .menuIcon {
      visibility: visible;
    }
  }

  .menuIcon {
    visibility: visible;
    position: absolute;
    cursor: pointer;
    right: 5px;
    width: 22px;
  }
}

.rightSideBarHidden {
  transform: translateX(0) !important;
}

.sidePanel {
  z-index: 100 !important;
}

.sidebarActions {
  display: flex;
  gap: 10px;
  justify-content: right;
  margin-top: 16px;

  .button {
    display: flex;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #fff;
    background-color: var(--prim-100);
    justify-content: center;
    align-items: center;
    gap: 7px;

    & > svg {
      height: 12px;
      width: 12px;
    }
  }
}

.clickable {
  align-items: center;
  background-color: var(--grey-20);
  border: 1px dashed gray;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px 10px !important;

  &.selected {
    color: white;
    background-color: black;
    border: none;
  }
  .mappedField {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.propertyLabel {
  display: flex;
}
.propertyRow {
  margin-top: 8px !important;
}

.handleContainer {
  pointer-events: none;
}

.dragHandle {
  position: absolute;
  box-sizing: border-box;
  background-color: white;
  border: 2px solid var(--outline-color);
  height: 9px;
  width: 9px;
  z-index: 3;
}

.dragZone {
  z-index: 4;
}
