// Helpers

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

#editor-panel {
  position: relative;
  height: calc(100vh - var(--main-nav-height));
  width: 100vw;
  display: flex;
  flex-direction: row;
  z-index: 10;
  overflow-x: hidden;

  .editor-header {
    position: relative;
    box-shadow: inset 0 -1px 0 0 var(--grey-border);
  }

  .editor-canvas {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;

    .editor-sidebar {
      border-left: 1px solid var(--grey-60);
      width: var(--editor-sidebar-width);
      height: 100%;
      background: white;
      box-shadow: -1px 0 0 0 var(--grey-border);
      z-index: 10;
      transition: all 0.3s cubic-bezier(0, 1, 0, 1);
      &.connections {
        width: var(--editor-connections-sidebar-width);
      }
      .row {
        margin: 0 !important;
        flex-wrap: nowrap;
      }

      .section-title {
        display: block;
      }

      .input-group-text {
        box-shadow: 0 0 0 1px #ced4da;
        background: transparent;
      }

      .editor-actions {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 16px;
        gap: 10px;
        right: calc(var(--editor-sidebar-width) + 45px);
      }
    }
  }
}

.fullscreen .toggle-full-screen {
  right: 25px;
  box-shadow: 0 0 0 2px var(--prim-100);

  svg {
    fill: var(--prim-100);
  }
}

/* Chrome, Safari, Edge, Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-arrows {
  -moz-appearance: textfield;
}
