.themeBuilder {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--main-nav-height) 1fr;
  grid-template-columns: 210px 1fr 350px;

  .elementNavigator {
    background-color: white;
    border-right: 1px solid #dee2e6;
    height: 100%;
    overflow-y: auto;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .themePreview {
    background: var(--grey-10);
    overflow-y: auto;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 16px;
    position: relative;

    .backgroundColorPicker {
      position: absolute;
      left: 20px;
      top: 30px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      color: var(--grey-70);
    }

    .viewportButtons {
      position: sticky;
      top: 0;
      background: white;
      border: 1px solid #dee2e6;
      border-radius: 8px;
      margin: 0 auto 15px auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      z-index: 10;
      height: 56px;
      width: 106px;
      padding: 0 4px;
      box-shadow: 0 3px 3px var(--grey-40);

      .viewportButton {
        height: 42px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        stroke: var(--grey-70);
        transition: all 250ms;
        border-radius: 4px;

        path {
          stroke: none;
          fill: var(--grey-70);
        }

        &:hover {
          background-color: rgb(246, 247, 250);
        }

        &.active {
          cursor: default;
          background-color: var(--prim-10);

          path {
            fill: var(--prim-100);
          }
        }

        &:last-child {
          border: none;
        }
      }
    }

    .elementSectionContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .borderOnly {
        border: 2px solid var(--grey-40);
        border-radius: 3px;
      }

      &:nth-child(odd) {
        margin-right: 24px;
      }

      .darkSectionHeader {
        color: white;
      }
    }

    .editStyle {
      position: absolute;
      right: -2px;
      top: -2px;
      border: 1px solid var(--prim-100);
      padding: 13px;
      border-top-right-radius: 3px;
      color: var(--prim-100);
      border-bottom-left-radius: 6px;
      cursor: pointer;
    }

    .sectionLabel {
      font-size: 10px;
      border-top: 1px solid var(--grey-40);
    }
    .assetContainer {
      max-height: 294px;
      overflow: auto;
    }
  }

  .styleTab {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    border-left: 1px solid #dee2e6;
    overflow-y: auto;
    background: white;

    div[class='row'] {
      margin: 0 !important;
    }

    div[class~='col'] {
      padding: 8px 0;
    }
  }
}
