.dropdown {
  // form control
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: var(--input-radius);
  color: var(--body-color);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;

  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px !important;
}

.dropdown:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  border-color: #86b7fe;
}

.dropdown:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.dropdownCaret {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'><path d='M0 0.776454L0.970744 0L5 4.2094L9.02926 0L10 0.776454L5 6L0 0.776454Z' fill='black'/></svg>");
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.error {
  border: 1px solid var(--red-border) !important;
}

.errorText {
  color: var(--red-border);
}
