.selectionPanel {
  width: var(--selection-panel-width);
  height: 100%;
  background: white;
  border-right: 1px solid var(--grey-border);
  z-index: 10;
  transition: all 250ms;
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  :global(.tab-content) {
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  .disabledMask {
    position: absolute;
    top: 61px;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.85);
    color: var(--grey-70);
    z-index: 2;
    text-align: center;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: var(--selection-panel-width);
    flex-direction: column;

    .disabledCta {
      margin-top: 10px;
      color: var(--prim-100);
      display: flex;
      cursor: pointer;

      svg {
        margin-left: 6px;
        stroke: var(--prim-100);
        width: 14px;
      }
    }
  }

  .flowContainer {
    padding: 15px;

    .editFlowButton {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: var(--prim-10);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--prim-100);
      font-weight: 500;
      margin-bottom: 30px;

      svg {
        margin-right: 6px;
      }
    }
  }
}
