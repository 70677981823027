.container {
  padding: 0 40px;
}

.progressBar {
  width: 100%;

  display: flex;
  gap: 24px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 40px;

  counter-reset: stepCounter;

  & .progressStep {
    counter-increment: stepCounter;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    color: var(--grey-70);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    transition: border-bottom-color 0.2s;
    flex: 1;

    &:not(.canNavigate) {
      &:hover {
        cursor: not-allowed;
      }
    }

    &.canNavigate {
      &:not(.active):hover {
        border-bottom-color: var(--grey-40);
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.complete {
      &:before {
        background-color: var(--system-valid);
        color: #fff;
      }
    }

    &.active {
      border-bottom: 2px solid var(--prim-100);
      color: var(--prim-100);

      &:hover {
        cursor: pointer;
      }

      &:before {
        background-color: var(--prim-100);
        color: white;
      }
    }

    &:before {
      content: counter(stepCounter);
      display: inline-block;
      margin-right: 5px;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background-color: var(--grey-40);
      color: var(--grey-80);
      font-size: 0.9rem;
    }
  }

  & .progressLine {
    padding: 10px 0;
    color: #d9d9d9;
  }
}

.navBar {
  display: flex;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 14px 40px;
  margin-top: 35px;

  .navBarError {
    flex: 1;
    flex-basis: 100%;
    flex-wrap: nowrap;

    & .errorDisplay {
      margin-bottom: 0 !important;
    }
  }

  .navBarSection {
    display: flex;
    gap: 10px;
    width: 100%;

    & button:not(:only-child):first-child {
      margin-right: auto;
    }

    &:not(:only-child):first-child {
      & button:only-child {
        margin-left: 0 !important;
      }
    }

    & button:only-child {
      margin-left: auto;
    }
  }

  .modalNavigation {
    flex: 1;
  }

  button {
    border: none;
    outline: none;
    padding: 8px 14px;
    font-size: 1rem;
    border-radius: 7px;
    transition: background-color ease-in-out 0.1s;
    min-width: 100px;
  }

  button.primary {
    background-color: var(--prim-100);
    color: white;

    &:hover {
      cursor: pointer;
      background-color: var(--button-primary-hover);
    }

    &:disabled {
      background-color: var(--prim-80);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  button.secondary {
    background-color: var(--grey-20);
  }
}

.errorDisplay {
  background-color: var(--prim-10);
  color: var(--prim-100);
  border: 1px solid var(--prim-100);
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 7px;
  text-wrap: nowrap;
}

.keyValueInputContainer {
  .keyValueInput {
    display: grid;
    gap: 3px;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .keyValueAddButton {
    background-color: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    color: var(--prim-100);
  }
}

.jsonPathInput,
.keyComponentButton {
  display: block;
  width: 100%;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: var(--body-color);
  appearance: none;
  background-color: white;
  background-clip: padding-box;
  border-radius: var(--input-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  & .jsonPathInputValueContainer {
    padding: 0.375rem 0.75rem !important;

    & > div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.keyComponentButton {
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #ced4da !important;
}

.docsButton {
  display: flex;
  gap: 5px;
  align-items: center;
}

.credential_picker {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

div.credential_spinner {
  width: 24px;
  height: 24px;
  margin: 4px;
  display: block;
  color: var(--prim-100);
}
