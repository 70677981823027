.message {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 30px;
}

.actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right;
  gap: 12px;
}
