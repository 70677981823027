.contextMenu {
  min-width: 150px;
  background: var(--grey-90);
  color: white;
  padding: 12px;
  gap: 8px;
  box-shadow: 0 0 4px 0 #727b9944;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  z-index: 10000 !important;
  width: max-content;
  overflow-y: auto;

  .contextMenuItem {
    cursor: pointer;
    display: flex;
    gap: 14px;
    align-items: center;
    transition: all 100ms;
    font-family: var(--font);
    font-weight: 300;
    padding: 4px 8px 4px 4px;

    &.disabled {
      cursor: default;
      color: var(--grey-50) !important;

      .contextMenuIcon svg {
        stroke: var(--grey-50);
      }
    }
    &.selected {
      background-color: #494e61;
      border-radius: 4px;
    }

    .contextMenuIcon {
      width: 20px;
      height: 20px;
      text-align: center;
    }

    &:not(.disabled):hover {
      background-color: #494e61;
      border-radius: 4px;
    }

    .contextMenuSubtitle {
      padding-left: 25px;
      flex: 1;
      text-align: right;
      color: #bdc2ce;
    }
  }

  .contextMenuDividor {
    border-bottom: 1px solid var(--grey-70);
    margin-left: -12px;
    margin-right: -12px;
  }
}
